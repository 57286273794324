import React from 'react'
import './Verification.css'
import { Grid, Menu, MenuItem, Pagination } from '@mui/material'
import { useState } from 'react'
import {TailSpin} from 'react-loader-spinner';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Space, Table } from 'antd'
import { AiFillEdit } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { getAllVerifications } from '../../redux/actions/verificationActions'
import VerificationModal from './VerificationModal';

const Verification = () => {

    const dispatch = useDispatch();

    const verifications = useSelector((state) => state.verifications)
    const updatedVerification = useSelector((state) => state.updatedVerification)

    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [id, setId] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const open = Boolean(anchorEl);

    const handleCloseModal = () => {
        setOpenModal(false);
        setId(null)
    }

    const handleClick = (event,record) => {
        setAnchorEl(event.currentTarget);
        setId(record.user._id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenModal = (type) => {
        setOpenModal(true);
        setModalType(type)
    }

    useEffect(() => {
        dispatch(getAllVerifications(page, pageSize))
    }, [page, pageSize, updatedVerification]);

    const verificationColumns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            elipses: 'true',
            width: '20%',
            render: (text,record) => (
                <Space size="middle">
                    {record.user.name}
                </Space>
            ),
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            elipses: 'true',
            width: '50%',
            render: (text,record) => (
                <Space size="middle">
                    {record.user.phoneNumber}
                </Space>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text,record) => (
                <Space size="middle">
                    {record.verificationStatus}
                </Space>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text,record) => (
                <Space size="middle">
                    <button className='action-button' onClick={(e) => handleClick(e, record)}> <BsThreeDotsVertical /> </button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        elevation={0}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => { handleOpenModal('edit'); handleClose()}}> <AiFillEdit/> &nbsp; Edit</MenuItem>
                    </Menu>
                </Space>
            ),
        },
    ];

    return (

        <div>
                
            <h2 className="page-header">Verification Requests</h2>

            <div className="card">

                <Grid container>
                    <Grid item xs={3}>
                        
                    </Grid>
                    <Grid item xs={5}>
                        
                    </Grid>
                </Grid>

                <br/>

                <div className="card__body">

                    {
                        verifications?.loading ?

                            <div className='loader-div'>
                                <TailSpin
                                    color='#C847F4'
                                    height={100}
                                    width={100}
                                />
                            </div>
                        :

                        verifications.error ?
                            <Alert severity="error"> {verifications.error}</Alert>
                        :

                        verifications.data &&

                        <>

                            <Table columns={verificationColumns} dataSource={verifications.data.verifications} pagination={false} />

                        </>
                    }
                    <VerificationModal openModal={openModal} handleCloseModal={handleCloseModal} modalType={modalType} id={id}/>
                </div>

                {
                    !verifications.loading &&
                    verifications.data &&
                    <div className='table-bottom-drawer'>
                        <Pagination page={page} count={Math.ceil(verifications.data.count/10)} onChange={(event, value) => setPage(value)}/>
                    </div>
                }
                
            </div>

        </div>

    )

}

export default Verification;