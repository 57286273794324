import * as api from '../../api/index.js';
import { INTERESTS_GET_FAIL, INTERESTS_GET_REQUEST, INTERESTS_GET_SUCCESS, INTEREST_ADD_FAIL, INTEREST_ADD_REQUEST, INTEREST_ADD_SUCCESS, INTEREST_DELETE_FAIL, INTEREST_DELETE_REQUEST, INTEREST_DELETE_SUCCESS, INTEREST_GET_FAIL, INTEREST_GET_REQUEST, INTEREST_GET_SUCCESS, INTEREST_UPDATE_FAIL, INTEREST_UPDATE_REQUEST, INTEREST_UPDATE_SUCCESS } from '../constants/interestConstants.js';

export const getAllInterests = (search, page, pageSize) => async (dispatch) => {

    dispatch({ type: INTERESTS_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllInterests(token, search, page, pageSize);
      
        dispatch({ type: INTERESTS_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: INTERESTS_GET_FAIL, payload: message });
    }

};

export const addInterest = (formData,handleCloseModal) => async (dispatch) => {

    dispatch({ type: INTEREST_ADD_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.addInterest(token, formData);
      
        dispatch({ type: INTEREST_ADD_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: INTEREST_ADD_FAIL, payload: message });
    }

};

export const updateInterest = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: INTEREST_UPDATE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.updateInterest(token, formData);
      
        dispatch({ type: INTEREST_UPDATE_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: INTEREST_UPDATE_FAIL, payload: message });
    }

};

export const deleteInterest = (id, handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: INTEREST_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteInterest(token, id);
      
        dispatch({ type: INTEREST_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: INTEREST_DELETE_FAIL, payload: message });
    }

};

export const getInterestById = (id) => async (dispatch) => {

    dispatch({ type: INTEREST_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getInterestById(token, id);
      
        dispatch({ type: INTEREST_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: INTEREST_GET_FAIL, payload: message });
    }

};