import React from 'react'
import { Alert, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { useEffect } from 'react';
import { getQuestionById, updateQuestion } from '../../../redux/actions/questionActions';
import { QUESTION_UPDATE_CLEAR } from '../../../redux/constants/questionConstants';

const initialState = { question: '' }

const EditQuestion = ({handleCloseModal, categoryId, level, questionId }) => {

    const dispatch = useDispatch();

    const updatedQuestion = useSelector((state) => state.updatedQuestion)
    const question = useSelector((state) => state.question)

    const [formData, setFormData] = useState(initialState);
    const [FormError, setFormError] = useState({
        question: false,
    });

    let Validate = () => {
        let value = true;

        let err = { question: false };
        setFormError({ ...err });

        if (formData.question === '') {
            value = false;
            err.level = 'Please enter Level!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(updateQuestion({ ...formData, categoryId, level, questionId }, handleCloseModal))
        
            clear();
        }
    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {
        if (!updatedQuestion.loading) {
            clear();
        }
    }, [updatedQuestion]);

    useEffect(() => {
        dispatch(getQuestionById(questionId))
    }, []);

    useEffect( async () => {

        clear();

        if (!question.loading && question.data && question.data.question) {
            setFormData({
                question: question.data.question.question,
            })
        }

    }, [question]);

    useEffect(() => {

        if (updatedQuestion.error) {
            dispatch({ type: QUESTION_UPDATE_CLEAR })
        }
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Edit Question </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                updatedQuestion.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                updatedQuestion.error ?
                    <Alert severity="error"> {updatedQuestion.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.question && 
                            <Alert severity="error"> {FormError.question}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Question *
                        </p>
                        <input className='input' type="text" value={formData.question} onChange={(e) => setFormData({...formData, question: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Save Question </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default EditQuestion