import { QUESTIONS_GET_FAIL, QUESTIONS_GET_REQUEST, QUESTIONS_GET_SUCCESS, QUESTION_ADD_CLEAR, QUESTION_ADD_FAIL, QUESTION_ADD_REQUEST, QUESTION_ADD_SUCCESS, QUESTION_DELETE_CLEAR, QUESTION_DELETE_FAIL, QUESTION_DELETE_REQUEST, QUESTION_DELETE_SUCCESS, QUESTION_GET_CLEAR, QUESTION_GET_FAIL, QUESTION_GET_REQUEST, QUESTION_GET_SUCCESS, QUESTION_UPDATE_CLEAR, QUESTION_UPDATE_FAIL, QUESTION_UPDATE_REQUEST, QUESTION_UPDATE_SUCCESS } from "../constants/questionConstants";

export const getAllQuestionsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case QUESTIONS_GET_REQUEST:
            return { loading: true };

        case QUESTIONS_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case QUESTIONS_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const addQuestionReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case QUESTION_ADD_REQUEST:
            return { loading: true };

        case QUESTION_ADD_SUCCESS:
            return { loading: false, data: action.payload };

        case QUESTION_ADD_FAIL:
            return { loading: false, error: action.payload };

        case QUESTION_ADD_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const deleteQuestionReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case QUESTION_DELETE_REQUEST:
            return { loading: true };

        case QUESTION_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case QUESTION_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case QUESTION_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const updateQuestionReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case QUESTION_UPDATE_REQUEST:
            return { loading: true };

        case QUESTION_UPDATE_SUCCESS:
            return { loading: false, data: action.payload };

        case QUESTION_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case QUESTION_UPDATE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getQuestionByIdReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case QUESTION_GET_REQUEST:
            return { loading: true };

        case QUESTION_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case QUESTION_GET_FAIL:
            return { loading: false, error: action.payload };

        case QUESTION_GET_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};