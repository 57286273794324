import React from 'react'
import { useHistory } from 'react-router-dom';

import './topnav.css'

const Topnav = () => {

    const history = useHistory();

    const logoutHandler = () => {

        localStorage.clear();
        history.push('/login');
    
    }

    return (
        <div className='topnav'>
            <div className="topnav__search">
                <input type="text" placeholder='Search' />
                <i className='bx bx-search'></i>
            </div>
            <div className="topnav__right">
                <div className="topnav__right-item">
                    
                    {/* dropdown here */}
                </div>
                <div className="topnav__right-item">
                    <button className='add-button' onClick={() => logoutHandler()}> Logout </button>
                </div>
            </div>
        </div>
    )
}

export default Topnav
