import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser } from '../../redux/actions/userActions';
import { deleteInterest } from '../../redux/actions/interestActions';
import { Alert } from '@mui/material';
import { INTEREST_DELETE_CLEAR } from '../../redux/constants/interestConstants';
import { USER_DELETE_CLEAR } from '../../redux/constants/userConstants';
import { deleteMusicCategory } from '../../redux/actions/musicCategoryActions';
import { MUSIC_CATEGORY_DELETE_CLEAR } from '../../redux/constants/musicCategoryConstants';
import { deleteMovie } from '../../redux/actions/movieActions';
import { MOVIE_DELETE_CLEAR } from '../../redux/constants/movieConstants';
import { deleteMusic } from '../../redux/actions/musicActions';
import { MUSIC_DELETE_CLEAR } from '../../redux/constants/musicConstants';
import { deleteSubscription } from '../../redux/actions/subscriptionActions';
import { SUBSCRIPTION_DELETE_CLEAR } from '../../redux/constants/subscriptionConstants';
import { deleteGameCategory, deleteGameLevel } from '../../redux/actions/gameActions';
import { GAME_CATEGORY_DELETE_CLEAR, GAME_LEVEL_DELETE_CLEAR } from '../../redux/constants/gameConstants';
import { deleteQuestion } from '../../redux/actions/questionActions';
import { QUESTION_DELETE_CLEAR } from '../../redux/constants/questionConstants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: '#FFFFFF',
    padding: '20px',
    borderRadius: '10px',
};

const DeleteModal = ({ openDeleteModal, handleCloseDeleteModal, modalType, id, gameLevel }) => {

    const dispatch = useDispatch();

    const deletedUser = useSelector((state) => state.deletedUser)
    const deletedInterest = useSelector((state) => state.deletedInterest)
    const deletedMusicCategory = useSelector((state) => state.deletedMusicCategory)
    const deletedMovie = useSelector((state) => state.deletedMovie)
    const deletedMusic = useSelector((state) => state.deletedMusic)
    const deletedSubscription = useSelector((state) => state.deletedSubscription)
    const deletedGameCategory = useSelector((state) => state.deletedGameCategory)
    const deletedGameLevel = useSelector((state) => state.deletedGameLevel)
    const deletedQuestion = useSelector((state) => state.deletedQuestion)

    const deleteHandler = () => {

        if (modalType === 'deleteUser') {
            dispatch(deleteUser(id,handleCloseDeleteModal))
        } else if (modalType === 'deleteInterest') {
            dispatch(deleteInterest(id,handleCloseDeleteModal))
        } else if (modalType === 'deleteMusicCategory') {
            dispatch(deleteMusicCategory(id,handleCloseDeleteModal))
        } else if (modalType === 'deleteMovie') {
            dispatch(deleteMovie(id,handleCloseDeleteModal))
        } else if (modalType === 'deleteMusic') {
            dispatch(deleteMusic(id,handleCloseDeleteModal))
        } else if (modalType === 'deleteSubscription') {
            dispatch(deleteSubscription(id,handleCloseDeleteModal))
        } else if (modalType === 'deleteGameCategory') {
            dispatch(deleteGameCategory(id,handleCloseDeleteModal))
        } else if (modalType === 'deleteGameLevel') {
            dispatch(deleteGameLevel(id, gameLevel, handleCloseDeleteModal))
        } else if (modalType === 'deleteQuestion') {
            dispatch(deleteQuestion(id, handleCloseDeleteModal))
        }

    }

    const handleCloseModal = () => {
        if (deletedUser.error) {
            dispatch({ type: USER_DELETE_CLEAR })
        }
        if (deletedInterest.error) {
            dispatch({ type: INTEREST_DELETE_CLEAR })
        }
        if (deletedMusicCategory.error) {
            dispatch({ type: MUSIC_CATEGORY_DELETE_CLEAR })
        }
        if (deletedMovie.error) {
            dispatch({ type: MOVIE_DELETE_CLEAR })
        }
        if (deletedMusic.error) {
            dispatch({ type: MUSIC_DELETE_CLEAR })
        }
        if (deletedSubscription.error) {
            dispatch({ type: SUBSCRIPTION_DELETE_CLEAR })
        }
        if (deletedGameCategory.error) {
            dispatch({ type: GAME_CATEGORY_DELETE_CLEAR })
        }
        if (deletedGameLevel.error) {
            dispatch({ type: GAME_LEVEL_DELETE_CLEAR })
        }
        if (deletedQuestion.error) {
            dispatch({ type: QUESTION_DELETE_CLEAR })
        }
        handleCloseDeleteModal()
    }

    return (

        <Modal
            open={openDeleteModal}
            onClose={() => handleCloseModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
        >
        <Box sx={style}>

            {

                deletedUser.error ?
                    <Alert severity="error"> {deletedUser.error}</Alert>
                :

                deletedInterest.error ?
                    <Alert severity="error"> {deletedInterest.error}</Alert>
                :

                deletedMusicCategory.error ?
                    <Alert severity="error"> {deletedMusicCategory.error}</Alert>
                :

                deletedMovie.error ?
                    <Alert severity="error"> {deletedMovie.error}</Alert>
                :

                deletedMusic.error ?
                    <Alert severity="error"> {deletedMusic.error}</Alert>
                :

                deletedSubscription.error ?
                    <Alert severity="error"> {deletedSubscription.error}</Alert>
                :

                deletedGameCategory.error ?
                    <Alert severity="error"> {deletedGameCategory.error}</Alert>
                :

                deletedGameLevel.error ?
                    <Alert severity="error"> {deletedGameLevel.error}</Alert>
                :

                deletedQuestion.error ?
                    <Alert severity="error"> {deletedQuestion.error}</Alert>
                :

                <>
            
                <p className='modal-title'> Delete </p>
                {
                    modalType === 'deleteUser' ?

                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this user ? </p>
                    :

                    modalType === 'deleteInterest' ?

                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this interest ? </p>
                    :

                    modalType === 'deleteMusicCategory' ?
                    
                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this Music Category ? </p>
                    :

                    modalType === 'deleteMovie' ?
                    
                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this Movie ? </p>
                    :

                    modalType === 'deleteMusic' ?
                    
                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this Music ? </p>
                    :

                    modalType === 'deleteSubscription' ?
                    
                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this Subscription ? </p>
                    :

                    modalType === 'deleteGameCategory' ?
                    
                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this Game Category ? </p>
                    :

                    modalType === 'deleteGameLevel' ?
                    
                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this Game Level ? </p>
                    :

                    modalType === 'deleteQuestion' ?
                    
                    <p style={{fontSize: '16px'}}> Are you sure you want to delete this Question ? </p>
                    :

                    <></>
                }
                
                <br/>
                <div className='form-button-container'>
                    <button className='form-action-button' onClick={() => handleCloseDeleteModal()}> Cancel </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className='form-action-button-submit' onClick={deleteHandler}> Delete </button>
                </div>

                </>

            }

        </Box>
        </Modal>

    )
}

export default DeleteModal