import { VERIFICATIONS_GET_FAIL, VERIFICATIONS_GET_REQUEST, VERIFICATIONS_GET_SUCCESS, VERIFICATION_GET_CLEAR, VERIFICATION_GET_FAIL, VERIFICATION_GET_REQUEST, VERIFICATION_GET_SUCCESS, VERIFICATION_UPDATE_CLEAR, VERIFICATION_UPDATE_FAIL, VERIFICATION_UPDATE_REQUEST, VERIFICATION_UPDATE_SUCCESS } from "../constants/verificationConstants";

export const getAllVerificationsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case VERIFICATIONS_GET_REQUEST:
            return { loading: true };

        case VERIFICATIONS_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case VERIFICATIONS_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const updateVerificationReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case VERIFICATION_UPDATE_REQUEST:
            return { loading: true };

        case VERIFICATION_UPDATE_SUCCESS:
            return { loading: false, data: action.payload };

        case VERIFICATION_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case VERIFICATION_UPDATE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getVerificationByIdReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case VERIFICATION_GET_REQUEST:
            return { loading: true };

        case VERIFICATION_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case VERIFICATION_GET_FAIL:
            return { loading: false, error: action.payload };

        case VERIFICATION_GET_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};