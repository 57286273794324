import * as api from '../../api/index.js';
import { VERIFICATIONS_GET_FAIL, VERIFICATIONS_GET_REQUEST, VERIFICATIONS_GET_SUCCESS, VERIFICATION_GET_FAIL, VERIFICATION_GET_REQUEST, VERIFICATION_GET_SUCCESS, VERIFICATION_UPDATE_FAIL, VERIFICATION_UPDATE_REQUEST, VERIFICATION_UPDATE_SUCCESS } from '../constants/verificationConstants.js';

export const getAllVerifications = (page, pageSize) => async (dispatch) => {

    dispatch({ type: VERIFICATIONS_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getPendingVerificationRequest(token, page, pageSize);
      
        dispatch({ type: VERIFICATIONS_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: VERIFICATIONS_GET_FAIL, payload: message });
    }

};

export const updateVerification = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: VERIFICATION_UPDATE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.updateVerificationStatus(token, formData);
      
        dispatch({ type: VERIFICATION_UPDATE_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: VERIFICATION_UPDATE_FAIL, payload: message });
    }

};

export const getVerificationById = (id) => async (dispatch) => {

    dispatch({ type: VERIFICATION_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getVerificationById(token, id);
      
        dispatch({ type: VERIFICATION_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: VERIFICATION_GET_FAIL, payload: message });
    }

};