import { SUBSCRIPTIONS_GET_FAIL, SUBSCRIPTIONS_GET_REQUEST, SUBSCRIPTIONS_GET_SUCCESS, SUBSCRIPTION_ADD_CLEAR, SUBSCRIPTION_ADD_FAIL, SUBSCRIPTION_ADD_REQUEST, SUBSCRIPTION_ADD_SUCCESS, SUBSCRIPTION_DELETE_CLEAR, SUBSCRIPTION_DELETE_FAIL, SUBSCRIPTION_DELETE_REQUEST, SUBSCRIPTION_DELETE_SUCCESS, SUBSCRIPTION_GET_CLEAR, SUBSCRIPTION_GET_FAIL, SUBSCRIPTION_GET_REQUEST, SUBSCRIPTION_GET_SUCCESS, SUBSCRIPTION_UPDATE_CLEAR, SUBSCRIPTION_UPDATE_FAIL, SUBSCRIPTION_UPDATE_REQUEST, SUBSCRIPTION_UPDATE_SUCCESS } from "../constants/subscriptionConstants";

export const getAllSubscriptionsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTIONS_GET_REQUEST:
            return { loading: true };

        case SUBSCRIPTIONS_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case SUBSCRIPTIONS_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const addSubscriptionReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ADD_REQUEST:
            return { loading: true };

        case SUBSCRIPTION_ADD_SUCCESS:
            return { loading: false, data: action.payload };

        case SUBSCRIPTION_ADD_FAIL:
            return { loading: false, error: action.payload };

        case SUBSCRIPTION_ADD_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const deleteSubscriptionReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_DELETE_REQUEST:
            return { loading: true };

        case SUBSCRIPTION_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case SUBSCRIPTION_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case SUBSCRIPTION_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const updateSubscriptionReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_UPDATE_REQUEST:
            return { loading: true };

        case SUBSCRIPTION_UPDATE_SUCCESS:
            return { loading: false, data: action.payload };

        case SUBSCRIPTION_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case SUBSCRIPTION_UPDATE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getSubscriptionByIdReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_GET_REQUEST:
            return { loading: true };

        case SUBSCRIPTION_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case SUBSCRIPTION_GET_FAIL:
            return { loading: false, error: action.payload };

        case SUBSCRIPTION_GET_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};