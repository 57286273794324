import axios from 'axios';

const API = axios.create({ baseURL: 'https://api.dilsay.app:5002/api/v1/' });
// const API = axios.create({ baseURL: 'https://localhost:5002/api/v1' });

export const login =  (formData) => API.post('auth/loginAdmin', formData)

export const deleteUser =  (token, id) => API.get(`admin/deleteUser/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const getAllUsers =  (token, search, page, pageSize) => API.get(`admin/getAllUsers?search=${search}&page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }})

export const addInterest =  (token, formData) => API.post('admin/addInterest', formData, { headers: { Authorization: `Bearer ${token}` }})
export const deleteInterest =  (token, id) => API.get(`admin/deleteInterest/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const getAllInterests =  (token, search, page, pageSize) => API.get(`admin/getAllInterests?search=${search}&page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }})
export const getInterestById =  (token, id) => API.get(`admin/getInterestById/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const updateInterest =  (token, formData) => API.post('admin/updateInterest', formData,  { headers: { Authorization: `Bearer ${token}` }})

export const addMusicCategory =  (token, formData) => API.post('music/addMusicCategory', formData, { headers: { Authorization: `Bearer ${token}` }})
export const deleteMusicCategory =  (token, id) => API.get(`music/deleteMusicCategory/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const getAllMusicCategories =  (token, search, page, pageSize) => API.get(`music/getAllMusicCategories?search=${search}&page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }})
export const getMusicCategoryById =  (token, id) => API.get(`music/getMusicCategoryById/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const updateMusicCategory =  (token, formData) => API.post('music/updateMusicCategory', formData,  { headers: { Authorization: `Bearer ${token}` }})

export const addMovie =  (token, formData) => API.post('music/addMovie', formData, { headers: { Authorization: `Bearer ${token}` }})
export const deleteMovie =  (token, id) => API.get(`music/deleteMovie/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const getAllMovies =  (token, search, page, pageSize) => API.get(`music/getAllMovies?search=${search}&page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }})
export const getMovieById =  (token, id) => API.get(`music/getMovieById/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const updateMovie =  (token, formData) => API.post('music/updateMovie', formData,  { headers: { Authorization: `Bearer ${token}` }})

export const addMusic =  (token, formData) => API.post('music/addMusic', formData, { headers: { Authorization: `Bearer ${token}` }})
export const deleteMusic =  (token, id) => API.get(`music/deleteMusic/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const getAllMusics =  (token, search, page, pageSize) => API.get(`music/getAllMusics?search=${search}&page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }})
export const getMusicById =  (token, id) => API.get(`music/getMusicById/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const updateMusic =  (token, formData) => API.post('music/updateMusic', formData,  { headers: { Authorization: `Bearer ${token}` }})

export const addSubscription =  (token, formData) => API.post('subscription/createSubscription', formData, { headers: { Authorization: `Bearer ${token}` }})
export const deleteSubscription =  (token, id) => API.get(`subscription/deleteSubscription/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const getAllSubscriptions =  (token, search, page, pageSize) => API.get(`subscription/getAllSubscriptions?search=${search}&page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }})
export const getSubscriptionById =  (token, id) => API.get(`subscription/getSubscriptionById/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const updateSubscription =  (token, formData) => API.post('subscription/updateSubscription', formData,  { headers: { Authorization: `Bearer ${token}` }})

export const addGameCategory =  (token, formData) => API.post('game/addGameCategory', formData, { headers: { Authorization: `Bearer ${token}` }})
export const deleteGameCategory =  (token, id) => API.get(`game/deleteGameCategory/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const getAllGameCategories =  (token, search, page, pageSize) => API.get(`game/getAllGameCategories?search=${search}&page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }})
export const getGameCategoryById =  (token, id) => API.get(`game/getGameCategoryById/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const updateGameCategory =  (token, formData) => API.post('game/updateGameCategory', formData,  { headers: { Authorization: `Bearer ${token}` }})

export const addGameLevel =  (token, formData) => API.post('game/addGameLevel', formData, { headers: { Authorization: `Bearer ${token}` }})
export const deleteGameLevel =  (token, categoryId, level) => API.post('game/deleteGameLevel', { categoryId, level }, { headers: { Authorization: `Bearer ${token}` }})
export const getAllGameLevels =  (token, id, page, pageSize) => API.get(`game/getAllGameLevels/${id}?page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` }})

export const addQuestion =  (token, formData) => API.post('game/addQuestion', formData, { headers: { Authorization: `Bearer ${token}` }})
export const deleteQuestion =  (token, id) => API.get(`game/deleteQuestion/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const getAllQuestions =  (token, search, gameCategoryId, level, page, pageSize) => API.post(`game/getAllQuestions?search=${search}&page=${page}&pageSize=${pageSize}`, { categoryId: gameCategoryId, level }, { headers: { Authorization: `Bearer ${token}` }})
export const getQuestionById =  (token, id) => API.get(`game/getQuestionById/${id}`, { headers: { Authorization: `Bearer ${token}` }})
export const updateQuestion =  (token, formData) => API.post('game/updateQuestion', formData,  { headers: { Authorization: `Bearer ${token}` }})

export const getPendingVerificationRequest =  (token, page, pageSize) => API.get(`admin/getPendingVerificationRequest?page=${page}&pageSize=${pageSize}`,  { headers: { Authorization: `Bearer ${token}` }})
export const updateVerificationStatus =  (token, formData) => API.post('admin/updateVerificationStatus', formData,  { headers: { Authorization: `Bearer ${token}` }})
export const getVerificationById =  (token, id) => API.get(`admin/getVerificationById/${id}`, { headers: { Authorization: `Bearer ${token}` }})
