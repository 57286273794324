import React from 'react'
import { Alert, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { useEffect } from 'react';
import { getMusicCategoryById, updateMusicCategory } from '../../redux/actions/musicCategoryActions';
import { MUSIC_CATEGORY_UPDATE_CLEAR } from '../../redux/constants/musicCategoryConstants';

const initialState = { categoryName: '' }

const EditMusicCategory = ({handleCloseModal, id}) => {

    const dispatch = useDispatch();

    const updatedMusicCategory = useSelector((state) => state.updatedMusicCategory)
    const musicCategory = useSelector((state) => state.musicCategory)

    const [formData, setFormData] = useState(initialState);
    const [FormError, setFormError] = useState({
        categoryName: false,
    });

    let Validate = () => {
        let value = true;

        let err = {  
            categoryName: false,
        };
        setFormError({ ...err });

        if (formData.categoryName === '') {
            value = false;
            err.categoryName = 'Please enter Category Name!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(updateMusicCategory({ ...formData, categoryId: id }, handleCloseModal))
        }
     
        clear();
    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {
        if (!updatedMusicCategory.loading) {
            clear();
        }
    }, [updatedMusicCategory]);

    useEffect(() => {
        dispatch(getMusicCategoryById(id))
    }, []);

    useEffect( async () => {

        clear();

        if (!musicCategory.loading && musicCategory.data && musicCategory.data.musicCategory) {
            setFormData({
                categoryName: musicCategory.data.musicCategory.categoryName,
            })
        }

    }, [musicCategory]);

    useEffect(() => {

        if (updatedMusicCategory.error) {
            dispatch({ type: MUSIC_CATEGORY_UPDATE_CLEAR })
        }
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Edit Music Category </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                updatedMusicCategory.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                updatedMusicCategory.error ?
                    <Alert severity="error"> {updatedMusicCategory.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.categoryName && 
                            <Alert severity="error"> {FormError.categoryName}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Category Name *
                        </p>
                        <input className='input' type="text" value={formData.categoryName} onChange={(e) => setFormData({...formData, categoryName: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Save Music Category </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default EditMusicCategory