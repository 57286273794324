import * as api from '../../api/index.js';
import { GAME_CATEGORIES_GET_FAIL, GAME_CATEGORIES_GET_REQUEST, GAME_CATEGORIES_GET_SUCCESS, GAME_CATEGORY_ADD_FAIL, GAME_CATEGORY_ADD_REQUEST, GAME_CATEGORY_ADD_SUCCESS, GAME_CATEGORY_DELETE_FAIL, GAME_CATEGORY_DELETE_REQUEST, GAME_CATEGORY_DELETE_SUCCESS, GAME_CATEGORY_GET_FAIL, GAME_CATEGORY_GET_REQUEST, GAME_CATEGORY_GET_SUCCESS, GAME_CATEGORY_UPDATE_FAIL, GAME_CATEGORY_UPDATE_REQUEST, GAME_CATEGORY_UPDATE_SUCCESS, GAME_LEVELS_GET_FAIL, GAME_LEVELS_GET_REQUEST, GAME_LEVELS_GET_SUCCESS, GAME_LEVEL_ADD_FAIL, GAME_LEVEL_ADD_REQUEST, GAME_LEVEL_ADD_SUCCESS, GAME_LEVEL_DELETE_FAIL, GAME_LEVEL_DELETE_REQUEST, GAME_LEVEL_DELETE_SUCCESS } from '../constants/gameConstants.js';

export const getAllGameCategories = (search, page, pageSize) => async (dispatch) => {

    dispatch({ type: GAME_CATEGORIES_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllGameCategories(token, search, page, pageSize);
      
        dispatch({ type: GAME_CATEGORIES_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: GAME_CATEGORIES_GET_FAIL, payload: message });
    }

};

export const addGameCategory = (formData,handleCloseModal) => async (dispatch) => {

    dispatch({ type: GAME_CATEGORY_ADD_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.addGameCategory(token, formData);
      
        dispatch({ type: GAME_CATEGORY_ADD_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: GAME_CATEGORY_ADD_FAIL, payload: message });
    }

};

export const updateGameCategory = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: GAME_CATEGORY_UPDATE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;


        const { data } = await api.updateGameCategory(token, formData);
      
        dispatch({ type: GAME_CATEGORY_UPDATE_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: GAME_CATEGORY_UPDATE_FAIL, payload: message });
    }

};

export const deleteGameCategory = (id,handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: GAME_CATEGORY_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteGameCategory(token, id);
      
        dispatch({ type: GAME_CATEGORY_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: GAME_CATEGORY_DELETE_FAIL, payload: message });
    }

};

export const getGameCategoryById = (id) => async (dispatch) => {

    dispatch({ type: GAME_CATEGORY_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getGameCategoryById(token, id);
      
        dispatch({ type: GAME_CATEGORY_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: GAME_CATEGORY_GET_FAIL, payload: message });
    }

};

export const getAllGameLevels = (id, page, pageSize) => async (dispatch) => {

    dispatch({ type: GAME_LEVELS_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllGameLevels(token, id, page, pageSize);
      
        dispatch({ type: GAME_LEVELS_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: GAME_LEVELS_GET_FAIL, payload: message });
    }

};

export const addGameLevel = (formData,handleCloseModal) => async (dispatch) => {

    dispatch({ type: GAME_LEVEL_ADD_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.addGameLevel(token, formData);
      
        dispatch({ type: GAME_LEVEL_ADD_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: GAME_LEVEL_ADD_FAIL, payload: message });
    }

};

export const deleteGameLevel = (id, level, handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: GAME_LEVEL_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteGameLevel(token, id, level);
      
        dispatch({ type: GAME_LEVEL_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: GAME_LEVEL_DELETE_FAIL, payload: message });
    }

};