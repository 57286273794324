import React from 'react'
import { Alert, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { useEffect } from 'react';
import { VERIFICATION_GET_CLEAR, VERIFICATION_UPDATE_CLEAR } from '../../redux/constants/verificationConstants';
import { getVerificationById, updateVerification } from '../../redux/actions/verificationActions';

const initialState = { verificationStatus: '', verificationMessage: '' }

const EditVerification = ({handleCloseModal, id}) => {

    const dispatch = useDispatch();

    const updatedVerification = useSelector((state) => state.updatedVerification)
    const verification = useSelector((state) => state.verification)

    const [formData, setFormData] = useState(initialState);

    const submitHandler = (e) => {

        e.preventDefault();
        dispatch(updateVerification({...formData, userId: id}, handleCloseModal))
     
        clear();

    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {
        if (!updatedVerification.loading) {
            clear();
        }
    }, [updatedVerification]);

    useEffect(() => {
        dispatch(getVerificationById(id))
    }, []);

    useEffect(() => {

        if (updatedVerification.error) {
            dispatch({ type: VERIFICATION_UPDATE_CLEAR })
        }
        if (verification.error) {
            dispatch({ type: VERIFICATION_GET_CLEAR })
        }
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Edit Verification </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                updatedVerification.loading || verification.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                updatedVerification.error || verification.error ?
                    <Alert severity="error"> {updatedVerification.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Verification Image *
                        </p>
                        {
                            verification?.data?.verification?.verificationImage &&
                            <>
                                <img style={{ maxHeight: '500px', maxWidth: '500px', marginBottom: '10px' }} src={verification.data.verification.verificationImage} alt='image'/>
                                <br/>
                            </>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Verification Image *
                        </p>
                        {
                            verification?.data?.verification?.user?.images &&

                            verification?.data?.verification?.user?.images.map((image) => (
                                <img key={image._id} style={{ maxHeight: '500px', maxWidth: '500px', marginBottom: '10px' }} src={image.link} alt='image'/>
                            ))
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Verification Status *
                        </p>
                        <select className='input' value={formData.verificationStatus} onChange={(e) => setFormData({...formData, verificationStatus: e.target.value})}>
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Accepted">Accepted</option>
                            <option value="Rejected">Rejected</option>
                        </select>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Verification Message *
                        </p>
                        <input className='input' type="text" value={formData.verificationMessage} onChange={(e) => setFormData({...formData, verificationMessage: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Save </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default EditVerification