import { MUSICS_GET_FAIL, MUSICS_GET_REQUEST, MUSICS_GET_SUCCESS, MUSIC_ADD_CLEAR, MUSIC_ADD_FAIL, MUSIC_ADD_REQUEST, MUSIC_ADD_SUCCESS, MUSIC_DELETE_CLEAR, MUSIC_DELETE_FAIL, MUSIC_DELETE_REQUEST, MUSIC_DELETE_SUCCESS, MUSIC_GET_CLEAR, MUSIC_GET_FAIL, MUSIC_GET_REQUEST, MUSIC_GET_SUCCESS, MUSIC_UPDATE_CLEAR, MUSIC_UPDATE_FAIL, MUSIC_UPDATE_REQUEST, MUSIC_UPDATE_SUCCESS } from "../constants/musicConstants";

export const getAllMusicsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSICS_GET_REQUEST:
            return { loading: true };

        case MUSICS_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSICS_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const addMusicReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_ADD_REQUEST:
            return { loading: true };

        case MUSIC_ADD_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_ADD_FAIL:
            return { loading: false, error: action.payload };

        case MUSIC_ADD_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const deleteMusicReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_DELETE_REQUEST:
            return { loading: true };

        case MUSIC_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case MUSIC_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const updateMusicReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_UPDATE_REQUEST:
            return { loading: true };

        case MUSIC_UPDATE_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case MUSIC_UPDATE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getMusicByIdReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_GET_REQUEST:
            return { loading: true };

        case MUSIC_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_GET_FAIL:
            return { loading: false, error: action.payload };

        case MUSIC_GET_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};