import React, { useState } from 'react'
import { Space, Table } from 'antd';
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Grid, Pagination } from '@mui/material';
import './Users.css'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../redux/actions/userActions';
import {TailSpin} from 'react-loader-spinner';
import Alert from '@mui/material/Alert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RiDeleteBin6Line } from 'react-icons/ri'
import DeleteModal from '../../components/Modals/DeleteModal';

const Users = () => {

    const dispatch = useDispatch();

    const users = useSelector((state) => state.users)
    const deletedUser = useSelector((state) => state.deletedUser)

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event,record) => {
        setAnchorEl(event.currentTarget);
        setId(record._id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteModal = (type) => {
        setOpenDeleteModal(true);
        setModalType(type)
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    useEffect(() => {
    
        dispatch(getAllUsers(search, page, pageSize))

    }, [search, page, pageSize, deletedUser]);

    const userColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text,record) => (
                <Space size="middle">
                    <button className='action-button' onClick={(e) => handleClick(e, record)}> <BsThreeDotsVertical /> </button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => { handleOpenDeleteModal('deleteUser'); handleClose()}}> <RiDeleteBin6Line/> &nbsp; Delete </MenuItem>
                    </Menu>
                </Space>
          ),
        },
    ];

  return (

    <div>
        
        <h2 className="page-header">Users</h2>

        <div className="card">

            <Grid container>
                <Grid item xs={3}>
                    <div className="topnav__search">
                        <input type="text" placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)}/>
                        <i className='bx bx-search'></i>
                    </div>
                </Grid>
            </Grid>

            <br/>

            <div className="card__body">

                {
                    users.loading ?

                        <div className='loader-div'>
                            <TailSpin
                                color='#C847F4'
                                height={100}
                                width={100}
                            />
                        </div>
                    :

                    users.error ?
                        <Alert severity="error"> {users.error}</Alert>
                    :

                    users.data &&

                    <>

                    <Table columns={userColumns} dataSource={users.data.users} pagination={false}/>
                    <br/>

                    </>
                }

                <DeleteModal openDeleteModal={openDeleteModal} handleCloseDeleteModal={handleCloseDeleteModal} modalType={modalType} id={id}/>
                
            </div>

            {
                !users.loading &&
                users.data &&
                <div className='table-bottom-drawer'>
                    <Pagination page={page} count={Math.ceil(users.data.count/10)} onChange={(event, value) => setPage(value)}/>
                </div>
            }

        </div>

    </div>

  )

}

export default Users