export const MUSICS_GET_REQUEST = 'MUSICS_GET_REQUEST'
export const MUSICS_GET_SUCCESS = 'MUSICS_GET_SUCCESS'
export const MUSICS_GET_FAIL = 'MUSICS_GET_FAIL'

export const MUSIC_ADD_REQUEST = 'MUSIC_ADD_REQUEST'
export const MUSIC_ADD_SUCCESS = 'MUSIC_ADD_SUCCESS'
export const MUSIC_ADD_FAIL = 'MUSIC_ADD_FAIL'
export const MUSIC_ADD_CLEAR = 'MUSIC_ADD_CLEAR'

export const MUSIC_DELETE_REQUEST = 'MUSIC_DELETE_REQUEST'
export const MUSIC_DELETE_SUCCESS = 'MUSIC_DELETE_SUCCESS'
export const MUSIC_DELETE_FAIL = 'MUSIC_DELETE_FAIL'
export const MUSIC_DELETE_CLEAR = 'MUSIC_DELETE_CLEAR'

export const MUSIC_UPDATE_REQUEST = 'MUSIC_UPDATE_REQUEST'
export const MUSIC_UPDATE_SUCCESS = 'MUSIC_UPDATE_SUCCESS'
export const MUSIC_UPDATE_FAIL = 'MUSIC_UPDATE_FAIL'
export const MUSIC_UPDATE_CLEAR = 'MUSIC_UPDATE_CLEAR'

export const MUSIC_GET_REQUEST = 'MUSIC_GET_REQUEST'
export const MUSIC_GET_SUCCESS = 'MUSIC_GET_SUCCESS'
export const MUSIC_GET_FAIL = 'MUSIC_GET_FAIL'
export const MUSIC_GET_CLEAR = 'MUSIC_GET_CLEAR'