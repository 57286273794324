import { GAME_CATEGORIES_GET_FAIL, GAME_CATEGORIES_GET_REQUEST, GAME_CATEGORIES_GET_SUCCESS, GAME_CATEGORY_ADD_CLEAR, GAME_CATEGORY_ADD_FAIL, GAME_CATEGORY_ADD_REQUEST, GAME_CATEGORY_ADD_SUCCESS, GAME_CATEGORY_DELETE_CLEAR, GAME_CATEGORY_DELETE_FAIL, GAME_CATEGORY_DELETE_REQUEST, GAME_CATEGORY_DELETE_SUCCESS, GAME_CATEGORY_GET_CLEAR, GAME_CATEGORY_GET_FAIL, GAME_CATEGORY_GET_REQUEST, GAME_CATEGORY_GET_SUCCESS, GAME_CATEGORY_UPDATE_CLEAR, GAME_CATEGORY_UPDATE_FAIL, GAME_CATEGORY_UPDATE_REQUEST, GAME_CATEGORY_UPDATE_SUCCESS, GAME_LEVELS_GET_FAIL, GAME_LEVELS_GET_REQUEST, GAME_LEVELS_GET_SUCCESS, GAME_LEVEL_ADD_CLEAR, GAME_LEVEL_ADD_FAIL, GAME_LEVEL_ADD_REQUEST, GAME_LEVEL_ADD_SUCCESS, GAME_LEVEL_DELETE_CLEAR, GAME_LEVEL_DELETE_FAIL, GAME_LEVEL_DELETE_REQUEST, GAME_LEVEL_DELETE_SUCCESS } from "../constants/gameConstants";

export const getAllGameCategoriesReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case GAME_CATEGORIES_GET_REQUEST:
            return { loading: true };

        case GAME_CATEGORIES_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case GAME_CATEGORIES_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const addGameCategoryReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case GAME_CATEGORY_ADD_REQUEST:
            return { loading: true };

        case GAME_CATEGORY_ADD_SUCCESS:
            return { loading: false, data: action.payload };

        case GAME_CATEGORY_ADD_FAIL:
            return { loading: false, error: action.payload };

        case GAME_CATEGORY_ADD_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const deleteGameCategoryReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case GAME_CATEGORY_DELETE_REQUEST:
            return { loading: true };

        case GAME_CATEGORY_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case GAME_CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case GAME_CATEGORY_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const updateGameCategoryReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case GAME_CATEGORY_UPDATE_REQUEST:
            return { loading: true };

        case GAME_CATEGORY_UPDATE_SUCCESS:
            return { loading: false, data: action.payload };

        case GAME_CATEGORY_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case GAME_CATEGORY_UPDATE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getGameCategoryByIdReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case GAME_CATEGORY_GET_REQUEST:
            return { loading: true };

        case GAME_CATEGORY_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case GAME_CATEGORY_GET_FAIL:
            return { loading: false, error: action.payload };

        case GAME_CATEGORY_GET_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getAllGameLevelsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case GAME_LEVELS_GET_REQUEST:
            return { loading: true };

        case GAME_LEVELS_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case GAME_LEVELS_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const addGameLevelReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case GAME_LEVEL_ADD_REQUEST:
            return { loading: true };

        case GAME_LEVEL_ADD_SUCCESS:
            return { loading: false, data: action.payload };

        case GAME_LEVEL_ADD_FAIL:
            return { loading: false, error: action.payload };

        case GAME_LEVEL_ADD_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const deleteGameLevelReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case GAME_LEVEL_DELETE_REQUEST:
            return { loading: true };

        case GAME_LEVEL_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case GAME_LEVEL_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case GAME_LEVEL_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};