import React from 'react'
import './App.css'
import Sidebar from './components/sidebar/Sidebar'
import TopNav from './components/topnav/TopNav'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import Users from './pages/Users/Users'
import Login from './pages/Login/Login'
import PublicRoute from './Routes/PublicRoute'
import PrivateRoute from './Routes/PrivateRoute'
import Interests from './pages/Interests/Interests'
import MusicCategory from './pages/MusicCategory/MusicCategory'
import Movies from './pages/Movies/Movies'
import Music from './pages/Music/Music'
import Subscriptions from './pages/Subscriptions/Subscriptions'
import GameCategory from './pages/Game/GameCategory/GameCategory'
import GameLevel from './pages/Game/GameLevel/GameLevel'
import Questions from './pages/Game/Questions/Questions'
import Verification from './pages/Verification/Verification'

const App = () => {

    const auth = localStorage.getItem('user');

    return (
        <HashRouter>
            <Switch>

                <PublicRoute path='/login' exact component={Login}/>

                <Route render={(props) => (
                    <div className="theme">
                        <Sidebar {...props}/>
                        <div className="layout__content">
                            <TopNav/>
                            <div className="layout__content-main">

                                <PrivateRoute path='/users' exact component={Users}/>
                                <PrivateRoute path='/interests' exact component={Interests}/>
                                <PrivateRoute path='/musicCategories' exact component={MusicCategory}/>
                                <PrivateRoute path='/movies' exact component={Movies}/>
                                <PrivateRoute path='/music' exact component={Music}/>
                                <PrivateRoute path='/subscriptions' exact component={Subscriptions}/>
                                <PrivateRoute path='/gameCategories' exact component={GameCategory}/>
                                <PrivateRoute path='/gameCategories/levels/:gameCategoryId' exact component={GameLevel}/>
                                <PrivateRoute path='/gameCategories/levels/:gameCategoryId/questions/:level' exact component={Questions}/>
                                <PrivateRoute path='/verification' exact component={Verification}/>
                                <Redirect from="/" to="/users"/>
                                 
                            </div>
                        </div>
                    </div>
                )}/>

                <Redirect from="/" to="/login"/>
                
            </Switch>
        </HashRouter>
    )
}

export default App;
