import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PublicRoute({ component: Component, ...rest }) {

  const auth = localStorage.getItem('user');
  
  return (
    <Route
      {...rest}
      render={(props) =>
        !auth ? (
          <Component {...props}></Component>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}