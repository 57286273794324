import * as api from '../../api/index.js';
import { USERS_GET_FAIL, USERS_GET_REQUEST, USERS_GET_SUCCESS, USER_DELETE_FAIL, USER_DELETE_REQUEST, USER_DELETE_SUCCESS } from '../constants/userConstants.js';

export const getAllUsers = (search, page, pageSize) => async (dispatch) => {

    dispatch({ type: USERS_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllUsers(token, search, page, pageSize);
      
        dispatch({ type: USERS_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: USERS_GET_FAIL, payload: message });
    }

};

export const deleteUser = (id, handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: USER_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteUser(token, id);
      
        dispatch({ type: USER_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: USER_DELETE_FAIL, payload: message });
    }

};