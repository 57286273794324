import * as api from '../../api/index.js';
import { SUBSCRIPTIONS_GET_FAIL, SUBSCRIPTIONS_GET_REQUEST, SUBSCRIPTIONS_GET_SUCCESS, SUBSCRIPTION_ADD_FAIL, SUBSCRIPTION_ADD_REQUEST, SUBSCRIPTION_ADD_SUCCESS, SUBSCRIPTION_DELETE_FAIL, SUBSCRIPTION_DELETE_REQUEST, SUBSCRIPTION_DELETE_SUCCESS, SUBSCRIPTION_GET_FAIL, SUBSCRIPTION_GET_REQUEST, SUBSCRIPTION_GET_SUCCESS, SUBSCRIPTION_UPDATE_FAIL, SUBSCRIPTION_UPDATE_REQUEST, SUBSCRIPTION_UPDATE_SUCCESS } from '../constants/subscriptionConstants.js';

export const getAllSubscriptions = (search, page, pageSize) => async (dispatch) => {

    dispatch({ type: SUBSCRIPTIONS_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllSubscriptions(token, search, page, pageSize);
      
        dispatch({ type: SUBSCRIPTIONS_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: SUBSCRIPTIONS_GET_FAIL, payload: message });
    }

};

export const addSubscription = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: SUBSCRIPTION_ADD_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;


        const { data } = await api.addSubscription(token, formData);
      
        dispatch({ type: SUBSCRIPTION_ADD_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: SUBSCRIPTION_ADD_FAIL, payload: message });
    }

};

export const updateSubscription = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: SUBSCRIPTION_UPDATE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.updateSubscription(token, formData);
      
        dispatch({ type: SUBSCRIPTION_UPDATE_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: SUBSCRIPTION_UPDATE_FAIL, payload: message });
    }

};

export const deleteSubscription = (id,handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: SUBSCRIPTION_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteSubscription(token, id);
      
        dispatch({ type: SUBSCRIPTION_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: SUBSCRIPTION_DELETE_FAIL, payload: message });
    }

};

export const getSubscriptionById = (id) => async (dispatch) => {

    dispatch({ type: SUBSCRIPTION_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getSubscriptionById(token, id);
      
        dispatch({ type: SUBSCRIPTION_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: SUBSCRIPTION_GET_FAIL, payload: message });
    }

};