import * as api from '../../api/index.js';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL} from '../constants/authConstants';

export const login = (formData, history) => async (dispatch) => {

    dispatch({ type: LOGIN_REQUEST });

    try {

        const {data} = await api.login(formData);
      
        dispatch({ type: LOGIN_SUCCESS, payload: data });

        history.push('/users');

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: LOGIN_FAIL, payload: message });
    }

}