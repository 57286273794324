export const GAME_CATEGORIES_GET_REQUEST = 'GAME_CATEGORIES_GET_REQUEST'
export const GAME_CATEGORIES_GET_SUCCESS = 'GAME_CATEGORIES_GET_SUCCESS'
export const GAME_CATEGORIES_GET_FAIL = 'GAME_CATEGORIES_GET_FAIL'

export const GAME_CATEGORY_ADD_REQUEST = 'GAME_CATEGORY_ADD_REQUEST'
export const GAME_CATEGORY_ADD_SUCCESS = 'GAME_CATEGORY_ADD_SUCCESS'
export const GAME_CATEGORY_ADD_FAIL = 'GAME_CATEGORY_ADD_FAIL'
export const GAME_CATEGORY_ADD_CLEAR = 'GAME_CATEGORY_ADD_CLEAR'

export const GAME_CATEGORY_DELETE_REQUEST = 'GAME_CATEGORY_DELETE_REQUEST'
export const GAME_CATEGORY_DELETE_SUCCESS = 'GAME_CATEGORY_DELETE_SUCCESS'
export const GAME_CATEGORY_DELETE_FAIL = 'GAME_CATEGORY_DELETE_FAIL'
export const GAME_CATEGORY_DELETE_CLEAR = 'GAME_CATEGORY_DELETE_CLEAR'

export const GAME_CATEGORY_UPDATE_REQUEST = 'GAME_CATEGORY_UPDATE_REQUEST'
export const GAME_CATEGORY_UPDATE_SUCCESS = 'GAME_CATEGORY_UPDATE_SUCCESS'
export const GAME_CATEGORY_UPDATE_FAIL = 'GAME_CATEGORY_UPDATE_FAIL'
export const GAME_CATEGORY_UPDATE_CLEAR = 'GAME_CATEGORY_UPDATE_CLEAR'

export const GAME_CATEGORY_GET_REQUEST = 'GAME_CATEGORY_GET_REQUEST'
export const GAME_CATEGORY_GET_SUCCESS = 'GAME_CATEGORY_GET_SUCCESS'
export const GAME_CATEGORY_GET_FAIL = 'GAME_CATEGORY_GET_FAIL'
export const GAME_CATEGORY_GET_CLEAR = 'GAME_CATEGORY_GET_CLEAR'

export const GAME_LEVELS_GET_REQUEST = 'GAME_LEVELS_GET_REQUEST'
export const GAME_LEVELS_GET_SUCCESS = 'GAME_LEVELS_GET_SUCCESS'
export const GAME_LEVELS_GET_FAIL = 'GAME_LEVELS_GET_FAIL'

export const GAME_LEVEL_ADD_REQUEST = 'GAME_LEVEL_ADD_REQUEST'
export const GAME_LEVEL_ADD_SUCCESS = 'GAME_LEVEL_ADD_SUCCESS'
export const GAME_LEVEL_ADD_FAIL = 'GAME_LEVEL_ADD_FAIL'
export const GAME_LEVEL_ADD_CLEAR = 'GAME_CATEGORY_ADD_CLEAR'

export const GAME_LEVEL_DELETE_REQUEST = 'GAME_CLEVEL_DELETE_REQUEST'
export const GAME_LEVEL_DELETE_SUCCESS = 'GAME_LEVEL_DELETE_SUCCESS'
export const GAME_LEVEL_DELETE_FAIL = 'GAME_LEVEL_DELETE_FAIL'
export const GAME_LEVEL_DELETE_CLEAR = 'GAME_LEVEL_DELETE_CLEAR'