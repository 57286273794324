import React from 'react'
import { Alert, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { useEffect } from 'react';
import { addMovie } from '../../redux/actions/movieActions';
import { MOVIE_ADD_CLEAR } from '../../redux/constants/movieConstants';

const initialState = { movieName: '', image: null }

const AddMovie = ({handleCloseModal}) => {

    const dispatch = useDispatch();

    const addedMovie = useSelector((state) => state.addedMovie)

    const [formData, setFormData] = useState(initialState);
    const [FormError, setFormError] = useState({
        movieName: false,
        image: false,
    });

    let Validate = () => {
        let value = true;

        let err = {  
            movieName: false,
            image: false,
        };
        setFormError({ ...err });

        if (formData.movieName === '') {
            value = false;
            err.movieName = 'Please enter Movie Name!';
        }

        if (formData.image === null) {
            value = false;
            err.image = 'Please select Logo!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(addMovie(formData,handleCloseModal))
        
            clear();
        }

    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {

        if (addedMovie.error) {
            dispatch({ type: MOVIE_ADD_CLEAR })
        }
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Add Movie </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                addedMovie.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                addedMovie.error ?
                    <Alert severity="error"> {addedMovie.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.movieName && 
                            <Alert severity="error"> {FormError.movieName}</Alert>
                        }
                        {
                            FormError.image && 
                            <Alert severity="error"> {FormError.image}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Movie Name *
                        </p>
                        <input className='input' type="text" value={formData.movieName} onChange={(e) => setFormData({...formData, movieName: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Logo *
                        </p>
                        {
                            formData.image &&
                            <>
                                <img style={{ maxHeight: '100px', maxWidth: '200px', marginBottom: '10px' }} src={URL.createObjectURL(formData.image)} alt='image'/>
                                <br/>
                            </>
                        }
                        <input type="file" multiple accept="image/*" onChange={(e) => setFormData({...formData, image: e.target.files[0]})} />
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Add Movie </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default AddMovie