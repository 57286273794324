import React from 'react'
import { Alert, Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { useEffect } from 'react';
import { getGameCategoryById, updateGameCategory } from '../../../redux/actions/gameActions';
import { GAME_CATEGORY_UPDATE_CLEAR } from '../../../redux/constants/gameConstants';

const initialState = { category: '', isFree: false }

const EditGameCategory = ({handleCloseModal, id}) => {

    const dispatch = useDispatch();

    const updatedGameCategory = useSelector((state) => state.updatedGameCategory)
    const gameCategory = useSelector((state) => state.gameCategory)

    const [formData, setFormData] = useState(initialState);
    const [FormError, setFormError] = useState({
        category: false,
        isFree: false
    });

    let Validate = () => {
        let value = true;

        let err = { category: false, isFree: false };
        setFormError({ ...err });

        if (formData.category === '') {
            value = false;
            err.category = 'Please enter Interest Name!';
        }

        if (formData.isFree === '') {
            value = false;
            err.isFree = 'Please enter Is Free!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(updateGameCategory({ ...formData, categoryId: id }, handleCloseModal))
        
            clear();
        }
    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {
        if (!updatedGameCategory.loading) {
            clear();
        }
    }, [updatedGameCategory]);

    useEffect(() => {
        dispatch(getGameCategoryById(id))
    }, []);

    useEffect( async () => {

        clear();

        if (!gameCategory.loading && gameCategory.data && gameCategory.data.gameCategory) {
            setFormData({
                category: gameCategory.data.gameCategory.category,
                isFree: gameCategory.data.gameCategory.isFree,
            })
        }

    }, [gameCategory]);

    useEffect(() => {

        if (updatedGameCategory.error) {
            dispatch({ type: GAME_CATEGORY_UPDATE_CLEAR })
        }
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Edit Game Category </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                updatedGameCategory.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                updatedGameCategory.error ?
                    <Alert severity="error"> {updatedGameCategory.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.category && 
                            <Alert severity="error"> {FormError.category}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        {
                            FormError.isFree && 
                            <Alert severity="error"> {FormError.isFree}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Game Category *
                        </p>
                        <input className='input' type="text" value={formData.category} onChange={(e) => setFormData({...formData, category: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Is Free?
                        </p>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{mt: 1, ml: 1, '&.Mui-checked': { color: '#C847F4' },}}
                            value={formData.isFree}
                            onChange={(e) => setFormData({...formData, isFree: e.target.value})}
                        >
                            <FormControlLabel value={true} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="Yes" />
                            <FormControlLabel value={false} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Save Game Category </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default EditGameCategory