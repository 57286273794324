import React, { useEffect } from 'react'
import { Alert, Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { addSubscription } from '../../redux/actions/subscriptionActions';
import { SUBSCRIPTION_ADD_CLEAR } from '../../redux/constants/subscriptionConstants';

const initialState = { title: '', price: '', validityPeriod: '', discount: '', isActive: true }
const featuresState = {
    isHideAge: true,
    isLimitedInvites: true,
    isLimitedAccepts: true,
    isLocationChange: true,
    isNoAds: true,
    isGameTries: true
}

const AddSubscription = ({handleCloseModal}) => {

    const dispatch = useDispatch();

    const addedSubscription = useSelector((state) => state.addedSubscription)

    const [formData, setFormData] = useState(initialState);
    const [features, setFeatures] = useState(featuresState);
    const [FormError, setFormError] = useState({
        title: false,
        price: false,
        validityPeriod: false,
        discount: false,
    });

    let Validate = () => {
        let value = true;

        let err = {  
            title: false,
            price: false,
            validityPeriod: false,
            discount: false,
        };
        setFormError({ ...err });

        if (formData.title === '') {
            value = false;
            err.title = 'Please enter Title!';
        }

        if (formData.price === '') {
            value = false;
            err.price = 'Please enter Price!';
        }

        if (formData.validityPeriod === '') {
            value = false;
            err.validityPeriod = 'Please enter Validity Period!';
        }

        if (formData.discount === '') {
            value = false;
            err.discount = 'Please enter Discount!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(addSubscription({...formData, features},handleCloseModal))
        }
     
        clear();
    }

    const clear = () => {
        setFormData(initialState);
        setFeatures(featuresState);
    };

    useEffect(() => {

        if (addedSubscription.error) {
            dispatch({ type: SUBSCRIPTION_ADD_CLEAR })
        }
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Add Subscription </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                addedSubscription.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                addedSubscription.error ?
                    <Alert severity="error"> {addedSubscription.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.title && 
                            <Alert severity="error"> {FormError.title}</Alert>
                        }
                        {
                            FormError.price && 
                            <Alert severity="error"> {FormError.price}</Alert>
                        }
                        {
                            FormError.validityPeriod && 
                            <Alert severity="error"> {FormError.validityPeriod}</Alert>
                        }
                        {
                            FormError.discount && 
                            <Alert severity="error"> {FormError.discount}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Title *
                        </p>
                        <input className='input' type="text" value={formData.title} onChange={(e) => setFormData({...formData, title: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Price *
                        </p>
                        <input className='input' type="number" value={formData.price} onChange={(e) => setFormData({...formData, price: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Validity Period *
                        </p>
                        <input className='input' type="text" value={formData.validityPeriod} onChange={(e) => setFormData({...formData, validityPeriod: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Discount *
                        </p>
                        <input className='input' type="number" value={formData.discount} onChange={(e) => setFormData({...formData, discount: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Is Active?
                        </p>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{mt: 1, ml: 1, '&.Mui-checked': { color: '#C847F4' },}}
                            value={formData.isActive}
                            onChange={(e) => setFormData({...formData, isActive: e.target.value})}
                        >
                            <FormControlLabel value={true} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="Yes" />
                            <FormControlLabel value={false} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Hide Age?
                        </p>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{mt: 1, ml: 1, '&.Mui-checked': { color: '#C847F4' },}}
                            value={features.isHideAge}
                            onChange={(e) => setFeatures({...features, isHideAge: e.target.value})}
                        >
                            <FormControlLabel value={true} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="Yes" />
                            <FormControlLabel value={false} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Limited Invites?
                        </p>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{mt: 1, ml: 1, '&.Mui-checked': { color: '#C847F4' },}}
                            value={features.isLimitedInvites}
                            onChange={(e) => setFeatures({...features, isLimitedInvites: e.target.value})}
                        >
                            <FormControlLabel value={true} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="Yes" />
                            <FormControlLabel value={false} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Limited Accepts?
                        </p>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{mt: 1, ml: 1, '&.Mui-checked': { color: '#C847F4' },}}
                            value={features.isLimitedAccepts}
                            onChange={(e) => setFeatures({...features, isLimitedAccepts: e.target.value})}
                        >
                            <FormControlLabel value={true} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="Yes" />
                            <FormControlLabel value={false} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Location Change?
                        </p>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{mt: 1, ml: 1, '&.Mui-checked': { color: '#C847F4' },}}
                            value={features.isLocationChange}
                            onChange={(e) => setFeatures({...features, isLocationChange: e.target.value})}
                        >
                            <FormControlLabel value={true} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="Yes" />
                            <FormControlLabel value={false} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            No Ads?
                        </p>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{mt: 1, ml: 1, '&.Mui-checked': { color: '#C847F4' },}}
                            value={features.isNoAds}
                            onChange={(e) => setFeatures({...features, isNoAds: e.target.value})}
                        >
                            <FormControlLabel value={true} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="Yes" />
                            <FormControlLabel value={false} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Game Tries?
                        </p>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{mt: 1, ml: 1, '&.Mui-checked': { color: '#C847F4' },}}
                            value={features.isGameTries}
                            onChange={(e) => setFeatures({...features, isGameTries: e.target.value})}
                        >
                            <FormControlLabel value={true} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="Yes" />
                            <FormControlLabel value={false} control={<Radio sx={{'&.Mui-checked': { color: '#C847F4' },}}/>} label="No" />
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Add Subscription </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default AddSubscription