import React from 'react'
import './Music.css'
import { MdAdd } from 'react-icons/md'
import { Grid, Menu, MenuItem, Pagination } from '@mui/material'
import { useState } from 'react'
import DeleteModal from '../../components/Modals/DeleteModal'
import {TailSpin} from 'react-loader-spinner';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Space, Table } from 'antd'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { AiFillEdit } from 'react-icons/ai'
import MusicModal from './MusicModal'
import { getAllMusics } from '../../redux/actions/musicActions'

const Music = () => {

    const dispatch = useDispatch();

    const musics = useSelector((state) => state.musics)
    const addedMusic = useSelector((state) => state.addedMusic)
    const updatedMusic = useSelector((state) => state.updatedMusic)
    const deletedMusic = useSelector((state) => state.deletedMusic)

    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [id, setId] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const open = Boolean(anchorEl);

    const handleCloseModal = () => {
        setOpenModal(false);
        setId(null)
    }

    const handleClick = (event,record) => {
        setAnchorEl(event.currentTarget);
        setId(record._id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenModal = (type) => {
        setOpenModal(true);
        setModalType(type)
    }

    const handleOpenDeleteModal = (type) => {
        setOpenDeleteModal(true);
        setModalType(type)
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    useEffect(() => {
        dispatch(getAllMusics(search, page, pageSize))
    }, [search, page, pageSize, addedMusic, updatedMusic, deletedMusic]);

    const musicColumns = [
        {
            title: 'Music Name',
            dataIndex: 'musicName',
        },
        {
            title: 'Movie Name',
            dataIndex: 'movieName',
            render: (text,record) => (
                <Space size="middle">
                    <img src={record.movieName.image} alt='logo' style={{height: '30px', width: '30px', margin: '0px'}}/>{record.movieName.movieName}
                </Space>
            ),
        },
        {
            title: 'Category Name',
            dataIndex: 'categoryName',
            render: (text,record) => (
                <Space size="middle">
                    {record.categoryName.categoryName}
                </Space>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text,record) => (
                <Space size="middle">
                    <button className='action-button' onClick={(e) => handleClick(e, record)}> <BsThreeDotsVertical /> </button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        elevation={0}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => { handleOpenModal('edit'); handleClose()}}> <AiFillEdit/> &nbsp; Edit</MenuItem>
                        <MenuItem onClick={() => { handleOpenDeleteModal('deleteMusic'); handleClose()}}> <RiDeleteBin6Line/> &nbsp; Delete</MenuItem>
                    </Menu>
                </Space>
            ),
        },
    ];

    return (

        <div>
                
            <h2 className="page-header"> Music </h2>

            <div className="card">

                <Grid container>
                    <Grid item xs={3}>
                        <div className="topnav__search">
                            <input type="text" placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)}/>
                            <i className='bx bx-search'></i>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        
                    </Grid>
                    <Grid item xs={4} className='button-grid'>
                        <button className='add-button' onClick={() => handleOpenModal('add')}> <MdAdd className='add-icon' /> &nbsp; Add Music </button>
                    </Grid>
                </Grid>

                <br/>

                <div className="card__body">

                    {
                        musics?.loading ?

                            <div className='loader-div'>
                                <TailSpin
                                    color='#C847F4'
                                    height={100}
                                    width={100}
                                />
                            </div>
                        :

                        musics?.error ?
                            <Alert severity="error"> {musics.error}</Alert>
                        :

                        musics.data &&

                        <>

                            <Table columns={musicColumns} dataSource={musics.data.musics} pagination={false} />

                        </>
                    }
                    <MusicModal openModal={openModal} handleCloseModal={handleCloseModal} modalType={modalType} id={id}/>
                    <DeleteModal openDeleteModal={openDeleteModal} handleCloseDeleteModal={handleCloseDeleteModal} modalType={modalType} id={id}/>
                </div>

                {
                    !musics.loading &&
                    musics.data &&
                    <div className='table-bottom-drawer'>
                        <Pagination page={page} count={Math.ceil(musics.data.count/10)} onChange={(event, value) => setPage(value)}/>
                    </div>
                }
                
            </div>

        </div>

    )

}

export default Music;