import React, { useEffect } from 'react'
import { Alert, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMusicCategory } from '../../redux/actions/musicCategoryActions';
import { TailSpin } from 'react-loader-spinner';
import { MUSIC_CATEGORY_ADD_CLEAR } from '../../redux/constants/musicCategoryConstants';

const initialState = { categoryName: '' }

const AddMusicCategory = ({handleCloseModal}) => {

    const dispatch = useDispatch();

    const addedMusicCategory = useSelector((state) => state.addedMusicCategory)

    const [formData, setFormData] = useState(initialState);
    const [FormError, setFormError] = useState({
        categoryName: false,
    });

    let Validate = () => {
        let value = true;

        let err = {  
            categoryName: false,
        };
        setFormError({ ...err });

        if (formData.categoryName === '') {
            value = false;
            err.categoryName = 'Please enter Category Name!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(addMusicCategory(formData,handleCloseModal))
        
            clear();
        }
    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {

        if (addedMusicCategory.error) {
            dispatch({ type: MUSIC_CATEGORY_ADD_CLEAR })
        }
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Add Music Category </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                addedMusicCategory.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                addedMusicCategory.error ?
                    <Alert severity="error"> {addedMusicCategory.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.categoryName && 
                            <Alert severity="error"> {FormError.categoryName}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Category Name *
                        </p>
                        <input className='input' type="text" value={formData.categoryName} onChange={(e) => setFormData({...formData, categoryName: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Add Music Category </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default AddMusicCategory