import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ component: Component, ...rest }) {

	const auth = localStorage.getItem('user');
	
	return (
		<Route
			{...rest}
			render={(props) =>
				auth ? 
				(
					JSON.parse(auth)?.user?.userType === 'Admin' ?
					(
						rest.path === '/users' ?
						(
							<Component {...props}{...rest}></Component>
						)
						:
						(
							<Redirect to='/users'/>
						)
					)
					:
					(
						<Component {...props}{...rest}></Component>
					)
				)
				: 
				(
					<Redirect to="/login" />
				)
			}
		/>
	);
}