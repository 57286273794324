import React from 'react'
import './Movies.css'
import { MdAdd } from 'react-icons/md'
import { Grid, Menu, MenuItem, Pagination } from '@mui/material'
import { useState } from 'react'
import DeleteModal from '../../components/Modals/DeleteModal'
import {TailSpin} from 'react-loader-spinner';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux'
import { getAllMovies } from '../../redux/actions/movieActions'
import { useEffect } from 'react'
import { Space, Table } from 'antd'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { AiFillEdit } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'
import MovieModal from './MovieModal'

const Movies = () => {

    const dispatch = useDispatch();

    const movies = useSelector((state) => state.movies)
    const addedMovie = useSelector((state) => state.addedMovie)
    const updatedMovie = useSelector((state) => state.updatedMovie)
    const deletedMovie = useSelector((state) => state.deletedMovie)

    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [id, setId] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const open = Boolean(anchorEl);

    const handleCloseModal = () => {
        setOpenModal(false);
        setId(null)
    }

    const handleClick = (event,record) => {
        setAnchorEl(event.currentTarget);
        setId(record._id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenModal = (type) => {
        setOpenModal(true);
        setModalType(type)
    }

    const handleOpenDeleteModal = (type) => {
        setOpenDeleteModal(true);
        setModalType(type)
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    useEffect(() => {
        dispatch(getAllMovies(search, page, pageSize))
    }, [search, page, pageSize, addedMovie, updatedMovie, deletedMovie]);

    const movieColumns = [
        {
            title: 'Movie Name',
            dataIndex: 'movieName',
            elipses: 'true',
            width: '90%',
            render: (text,record) => (
                <Space size="middle">
                    <img src={record.image} alt='logo' style={{height: '30px', width: '30px', margin: '0px'}}/>{record.movieName}
                </Space>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text,record) => (
                <Space size="middle">
                    <button className='action-button' onClick={(e) => handleClick(e, record)}> <BsThreeDotsVertical /> </button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        elevation={0}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => { handleOpenModal('edit'); handleClose()}}> <AiFillEdit/> &nbsp; Edit</MenuItem>
                        <MenuItem onClick={() => { handleOpenDeleteModal('deleteMovie'); handleClose()}}> <RiDeleteBin6Line/> &nbsp; Delete</MenuItem>
                    </Menu>
                </Space>
            ),
        },
    ];

    return (

        <div>
                
            <h2 className="page-header">Movies</h2>

            <div className="card">

                <Grid container>
                    <Grid item xs={3}>
                        <div className="topnav__search">
                            <input type="text" placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)}/>
                            <i className='bx bx-search'></i>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        
                    </Grid>
                    <Grid item xs={4} className='button-grid'>
                        <button className='add-button' onClick={() => handleOpenModal('add')}> <MdAdd className='add-icon' /> &nbsp; Add Movie </button>
                    </Grid>
                </Grid>

                <br/>

                <div className="card__body">

                    {
                        movies?.loading ?

                            <div className='loader-div'>
                                <TailSpin
                                    color='#C847F4'
                                    height={100}
                                    width={100}
                                />
                            </div>
                        :

                        movies.error ?
                            <Alert severity="error"> {movies.error}</Alert>
                        :

                        movies.data &&

                        <>

                            <Table columns={movieColumns} dataSource={movies.data.movies} pagination={false} />

                        </>
                    }
                    <MovieModal openModal={openModal} handleCloseModal={handleCloseModal} modalType={modalType} id={id}/>
                    <DeleteModal openDeleteModal={openDeleteModal} handleCloseDeleteModal={handleCloseDeleteModal} modalType={modalType} id={id}/>
                </div>

                {
                    !movies.loading &&
                    movies.data &&
                    <div className='table-bottom-drawer'>
                        <Pagination page={page} count={Math.ceil(movies.data.count/10)} onChange={(event, value) => setPage(value)}/>
                    </div>
                }
                
            </div>

        </div>

    )

}

export default Movies;