export const SUBSCRIPTIONS_GET_REQUEST = 'SUBSCRIPTIONS_GET_REQUEST'
export const SUBSCRIPTIONS_GET_SUCCESS = 'SUBSCRIPTIONS_GET_SUCCESS'
export const SUBSCRIPTIONS_GET_FAIL = 'SUBSCRIPTIONS_GET_FAIL'

export const SUBSCRIPTION_ADD_REQUEST = 'SUBSCRIPTION_ADD_REQUEST'
export const SUBSCRIPTION_ADD_SUCCESS = 'SUBSCRIPTION_ADD_SUCCESS'
export const SUBSCRIPTION_ADD_FAIL = 'SUBSCRIPTION_ADD_FAIL'
export const SUBSCRIPTION_ADD_CLEAR = 'SUBSCRIPTION_ADD_CLEAR'

export const SUBSCRIPTION_DELETE_REQUEST = 'SUBSCRIPTION_DELETE_REQUEST'
export const SUBSCRIPTION_DELETE_SUCCESS = 'SUBSCRIPTION_DELETE_SUCCESS'
export const SUBSCRIPTION_DELETE_FAIL = 'SUBSCRIPTION_DELETE_FAIL'
export const SUBSCRIPTION_DELETE_CLEAR = 'SUBSCRIPTION_DELETE_CLEAR'

export const SUBSCRIPTION_UPDATE_REQUEST = 'SUBSCRIPTION_UPDATE_REQUEST'
export const SUBSCRIPTION_UPDATE_SUCCESS = 'SUBSCRIPTION_UPDATE_SUCCESS'
export const SUBSCRIPTION_UPDATE_FAIL = 'SUBSCRIPTION_UPDATE_FAIL'
export const SUBSCRIPTION_UPDATE_CLEAR = 'SUBSCRIPTION_UPDATE_CLEAR'

export const SUBSCRIPTION_GET_REQUEST = 'SUBSCRIPTION_GET_REQUEST'
export const SUBSCRIPTION_GET_SUCCESS = 'SUBSCRIPTION_GET_SUCCESS'
export const SUBSCRIPTION_GET_FAIL = 'SUBSCRIPTION_GET_FAIL'
export const SUBSCRIPTION_GET_CLEAR = 'SUBSCRIPTION_GET_CLEAR'