import { INTERESTS_GET_FAIL, INTERESTS_GET_REQUEST, INTERESTS_GET_SUCCESS, INTEREST_ADD_CLEAR, INTEREST_ADD_FAIL, INTEREST_ADD_REQUEST, INTEREST_ADD_SUCCESS, INTEREST_DELETE_CLEAR, INTEREST_DELETE_FAIL, INTEREST_DELETE_REQUEST, INTEREST_DELETE_SUCCESS, INTEREST_GET_CLEAR, INTEREST_GET_FAIL, INTEREST_GET_REQUEST, INTEREST_GET_SUCCESS, INTEREST_UPDATE_CLEAR, INTEREST_UPDATE_FAIL, INTEREST_UPDATE_REQUEST, INTEREST_UPDATE_SUCCESS } from "../constants/interestConstants";

export const getAllInterestsReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case INTERESTS_GET_REQUEST:
            return { loading: true };

        case INTERESTS_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case INTERESTS_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const addInterestReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case INTEREST_ADD_REQUEST:
            return { loading: true };

        case INTEREST_ADD_SUCCESS:
            return { loading: false, data: action.payload };

        case INTEREST_ADD_FAIL:
            return { loading: false, error: action.payload };

        case INTEREST_ADD_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const deleteInterestReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case INTEREST_DELETE_REQUEST:
            return { loading: true };

        case INTEREST_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case INTEREST_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case INTEREST_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const updateInterestReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case INTEREST_UPDATE_REQUEST:
            return { loading: true };

        case INTEREST_UPDATE_SUCCESS:
            return { loading: false, data: action.payload };

        case INTEREST_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case INTEREST_UPDATE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getInterestByIdReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case INTEREST_GET_REQUEST:
            return { loading: true };

        case INTEREST_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case INTEREST_GET_FAIL:
            return { loading: false, error: action.payload };

        case INTEREST_GET_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};