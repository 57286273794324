import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT} from '../constants/authConstants';

export const loginReducer = (state= {loading: false, authData: null} , action) => {

    switch(action.type) {

        case LOGIN_REQUEST:
            return { loading: true };

        case LOGIN_SUCCESS:
            localStorage.setItem('user', JSON.stringify(action.payload));
            return {...state, loading: false, authData: action.payload};

        case LOGIN_FAIL:
            return {...state, loading: false, error: action.payload};
        
        case LOGOUT:
            localStorage.clear();
            return {...state, authData: null};
        
        default: 
            return state;

    }

}