import * as api from '../../api/index.js';
import { MUSICS_GET_FAIL, MUSICS_GET_REQUEST, MUSICS_GET_SUCCESS, MUSIC_ADD_FAIL, MUSIC_ADD_REQUEST, MUSIC_ADD_SUCCESS, MUSIC_DELETE_FAIL, MUSIC_DELETE_REQUEST, MUSIC_DELETE_SUCCESS, MUSIC_GET_FAIL, MUSIC_GET_REQUEST, MUSIC_GET_SUCCESS, MUSIC_UPDATE_FAIL, MUSIC_UPDATE_REQUEST, MUSIC_UPDATE_SUCCESS } from '../constants/musicConstants.js';

export const getAllMusics = (search, page, pageSize) => async (dispatch) => {

    dispatch({ type: MUSICS_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllMusics(token, search, page, pageSize);
      
        dispatch({ type: MUSICS_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSICS_GET_FAIL, payload: message });
    }

};

export const addMusic = (fd, handleCloseModal) => async (dispatch) => {

    dispatch({ type: MUSIC_ADD_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const formData = new FormData();

        formData.append("musicName", fd.musicName)
        formData.append("audio", fd.audio)
        formData.append("categoryId", fd.categoryId)
        formData.append("movieId", fd.movieId)

        const { data } = await api.addMusic(token, formData);
      
        dispatch({ type: MUSIC_ADD_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_ADD_FAIL, payload: message });
    }

};

export const updateMusic = (fd, handleCloseModal) => async (dispatch) => {

    dispatch({ type: MUSIC_UPDATE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const formData = new FormData();

        formData.append("musicId", fd.musicId)
        formData.append("musicName", fd.musicName)
        formData.append("audio", fd.audio)
        formData.append("categoryId", fd.categoryId)
        formData.append("movieId", fd.movieId)

        const { data } = await api.updateMusic(token, formData);
      
        dispatch({ type: MUSIC_UPDATE_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_UPDATE_FAIL, payload: message });
    }

};

export const deleteMusic = (id,handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: MUSIC_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteMusic(token, id);
      
        dispatch({ type: MUSIC_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_DELETE_FAIL, payload: message });
    }

};

export const getMusicById = (id) => async (dispatch) => {

    dispatch({ type: MUSIC_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getMusicById(token, id);
      
        dispatch({ type: MUSIC_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_GET_FAIL, payload: message });
    }

};