import { MUSIC_CATEGORIES_GET_FAIL, MUSIC_CATEGORIES_GET_REQUEST, MUSIC_CATEGORIES_GET_SUCCESS, MUSIC_CATEGORY_ADD_CLEAR, MUSIC_CATEGORY_ADD_FAIL, MUSIC_CATEGORY_ADD_REQUEST, MUSIC_CATEGORY_ADD_SUCCESS, MUSIC_CATEGORY_DELETE_CLEAR, MUSIC_CATEGORY_DELETE_FAIL, MUSIC_CATEGORY_DELETE_REQUEST, MUSIC_CATEGORY_DELETE_SUCCESS, MUSIC_CATEGORY_GET_CLEAR, MUSIC_CATEGORY_GET_FAIL, MUSIC_CATEGORY_GET_REQUEST, MUSIC_CATEGORY_GET_SUCCESS, MUSIC_CATEGORY_UPDATE_CLEAR, MUSIC_CATEGORY_UPDATE_FAIL, MUSIC_CATEGORY_UPDATE_REQUEST, MUSIC_CATEGORY_UPDATE_SUCCESS } from "../constants/musicCategoryConstants";

export const getAllMusicCategoriesReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_CATEGORIES_GET_REQUEST:
            return { loading: true };

        case MUSIC_CATEGORIES_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_CATEGORIES_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const addMusicCategoryReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_CATEGORY_ADD_REQUEST:
            return { loading: true };

        case MUSIC_CATEGORY_ADD_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_CATEGORY_ADD_FAIL:
            return { loading: false, error: action.payload };

        case MUSIC_CATEGORY_ADD_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const deleteMusicCategoryReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_CATEGORY_DELETE_REQUEST:
            return { loading: true };

        case MUSIC_CATEGORY_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_CATEGORY_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case MUSIC_CATEGORY_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const updateMusicCategoryReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_CATEGORY_UPDATE_REQUEST:
            return { loading: true };

        case MUSIC_CATEGORY_UPDATE_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_CATEGORY_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case MUSIC_CATEGORY_UPDATE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getMusicCategoryByIdReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MUSIC_CATEGORY_GET_REQUEST:
            return { loading: true };

        case MUSIC_CATEGORY_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case MUSIC_CATEGORY_GET_FAIL:
            return { loading: false, error: action.payload };

        case MUSIC_CATEGORY_GET_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};