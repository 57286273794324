export const MOVIES_GET_REQUEST = 'MOVIES_GET_REQUEST'
export const MOVIES_GET_SUCCESS = 'MOVIES_GET_SUCCESS'
export const MOVIES_GET_FAIL = 'MOVIES_GET_FAIL'

export const MOVIE_ADD_REQUEST = 'MOVIE_ADD_REQUEST'
export const MOVIE_ADD_SUCCESS = 'MOVIE_ADD_SUCCESS'
export const MOVIE_ADD_FAIL = 'MOVIE_ADD_FAIL'
export const MOVIE_ADD_CLEAR = 'MOVIE_ADD_CLEAR'

export const MOVIE_DELETE_REQUEST = 'MOVIE_DELETE_REQUEST'
export const MOVIE_DELETE_SUCCESS = 'MOVIE_DELETE_SUCCESS'
export const MOVIE_DELETE_FAIL = 'MOVIE_DELETE_FAIL'
export const MOVIE_DELETE_CLEAR = 'MOVIE_DELETE_CLEAR'

export const MOVIE_UPDATE_REQUEST = 'MOVIE_UPDATE_REQUEST'
export const MOVIE_UPDATE_SUCCESS = 'MOVIE_UPDATE_SUCCESS'
export const MOVIE_UPDATE_FAIL = 'MOVIE_UPDATE_FAIL'
export const MOVIE_UPDATE_CLEAR = 'MOVIE_UPDATE_CLEAR'

export const MOVIE_GET_REQUEST = 'MOVIE_GET_REQUEST'
export const MOVIE_GET_SUCCESS = 'MOVIE_GET_SUCCESS'
export const MOVIE_GET_FAIL = 'MOVIE_GET_FAIL'
export const MOVIE_GET_CLEAR = 'MOVIE_GET_CLEAR'