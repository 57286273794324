import React, { useEffect } from 'react'
import { Alert, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { addGameLevel } from '../../../redux/actions/gameActions';
import { GAME_LEVEL_ADD_CLEAR } from '../../../redux/constants/gameConstants';

const initialState = { level: '' }

const AddGameLevel = ({ handleCloseModal, id }) => {

    const dispatch = useDispatch();

    const addedGameLevel = useSelector((state) => state.addedGameLevel)

    const [formData, setFormData] = useState(initialState);
    const [FormError, setFormError] = useState({
        level: false,
    });

    let Validate = () => {
        let value = true;

        let err = { level: false };
        setFormError({ ...err });

        if (formData.level === '') {
            value = false;
            err.level = 'Please enter Level!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(addGameLevel({...formData, categoryId: id },handleCloseModal))
        
            clear();
        }
    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {

        if (addedGameLevel.error) {
            dispatch({ type: GAME_LEVEL_ADD_CLEAR })
        }
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Add Game Level </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                addedGameLevel.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                addedGameLevel.error ?
                    <Alert severity="error"> {addedGameLevel.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.level && 
                            <Alert severity="error"> {FormError.level}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Game Level *
                        </p>
                        <input className='input' type="number" value={formData.level} onChange={(e) => setFormData({...formData, level: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Add Game Level </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default AddGameLevel