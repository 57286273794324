import { Alert, Box, Grid } from '@mui/material'
import React from 'react'
import './Login.css'
import LoginImage from '../../assets/images/login.png'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/authActions';
import { useHistory } from 'react-router-dom';
import Dilsay from '../../assets/images/dilsay.png';
import {TailSpin} from 'react-loader-spinner';

const initialState = {email: '', password: ''}

const Login = () => {

	const dispatch = useDispatch();

	const loggedIn = useSelector((state) => state.auth)

	const [formData, setFormData] = useState(initialState);

	const history = useHistory();

	useEffect(() => {
		
	}, []);

	const loginHandler = (e) => {

		e.preventDefault();
		dispatch(login(formData, history))
	
		clear();

	}

	const clear = () => {
		setFormData(initialState);
	};

	return (

		<Grid container>

			<Grid item xs={12} md={6}>

				<div className='login-image-container'>
				<img src={LoginImage} alt='login' />
				</div>

			</Grid>

			<Grid item xs={12} md={6} className='login-data-container'>

				<div>

					<p className='welcome-text'> <img src={Dilsay} alt='logo'/> </p>
					
					<p className='login-text'> Please enter your credential to login your account. </p>

					{
						loggedIn.error &&
						<Alert severity="error"> {loggedIn.error}</Alert>
					}

					<Box
						component="form"
						sx={{
							'& .MuiTextField-root': { my: 1 },
						}}
						autoComplete="off"
						onSubmit={(e) => loginHandler(e)}
					>
						<p className='input-label'>
							Email Address *
						</p>
						<input className='input' type="email" value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})}/>

						<p className='input-label'>
							Password *
						</p>
						<input className='input' type="password" value={formData.password} onChange={(e) => setFormData({...formData, password: e.target.value})}/>
						
						{/* <p className='forgot-password'> Forgot Password? </p> */}

						{

							loggedIn.loading ?
							<div className='loader-div'>
								<TailSpin
									color='#C847F4'
									height={40}
									width={40}
								/>
							</div>
							:
							<button className='login-button' type='submit'> Log In </button>

						}
						
						
					</Box>

				</div>
				
			</Grid>

		</Grid>

	)
  
}

export default Login