export const VERIFICATIONS_GET_REQUEST = 'VERIFICATIONS_GET_REQUEST'
export const VERIFICATIONS_GET_SUCCESS = 'VERIFICATIONS_GET_SUCCESS'
export const VERIFICATIONS_GET_FAIL = 'VERIFICATIONS_GET_FAIL'

export const VERIFICATION_UPDATE_REQUEST = 'VERIFICATION_UPDATE_REQUEST'
export const VERIFICATION_UPDATE_SUCCESS = 'VERIFICATION_UPDATE_SUCCESS'
export const VERIFICATION_UPDATE_FAIL = 'VERIFICATION_UPDATE_FAIL'
export const VERIFICATION_UPDATE_CLEAR = 'VERIFICATION_UPDATE_CLEAR'

export const VERIFICATION_GET_REQUEST = 'VERIFICATION_GET_REQUEST'
export const VERIFICATION_GET_SUCCESS = 'VERIFICATION_GET_SUCCESS'
export const VERIFICATION_GET_FAIL = 'VERIFICATION_GET_FAIL'
export const VERIFICATION_GET_CLEAR = 'VERIFICATION_GET_CLEAR'