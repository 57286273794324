import * as api from '../../api/index.js';
import { QUESTIONS_GET_FAIL, QUESTIONS_GET_REQUEST, QUESTIONS_GET_SUCCESS, QUESTION_ADD_FAIL, QUESTION_ADD_REQUEST, QUESTION_ADD_SUCCESS, QUESTION_DELETE_FAIL, QUESTION_DELETE_REQUEST, QUESTION_DELETE_SUCCESS, QUESTION_GET_FAIL, QUESTION_GET_REQUEST, QUESTION_GET_SUCCESS, QUESTION_UPDATE_FAIL, QUESTION_UPDATE_REQUEST, QUESTION_UPDATE_SUCCESS } from '../constants/questionConstants.js';

export const getAllQuestions = (search, gameCategoryId, level, page, pageSize) => async (dispatch) => {

    dispatch({ type: QUESTIONS_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllQuestions(token, search, gameCategoryId, level, page, pageSize);
      
        dispatch({ type: QUESTIONS_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: QUESTIONS_GET_FAIL, payload: message });
    }

};

export const addQuestion = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: QUESTION_ADD_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;


        const { data } = await api.addQuestion(token, formData);
      
        dispatch({ type: QUESTION_ADD_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: QUESTION_ADD_FAIL, payload: message });
    }

};

export const updateQuestion = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: QUESTION_UPDATE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.updateQuestion(token, formData);
      
        dispatch({ type: QUESTION_UPDATE_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: QUESTION_UPDATE_FAIL, payload: message });
    }

};

export const deleteQuestion = (id, handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: QUESTION_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteQuestion(token, id);
      
        dispatch({ type: QUESTION_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: QUESTION_DELETE_FAIL, payload: message });
    }

};

export const getQuestionById = (id) => async (dispatch) => {

    dispatch({ type: QUESTION_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getQuestionById(token, id);
      
        dispatch({ type: QUESTION_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: QUESTION_GET_FAIL, payload: message });
    }

};