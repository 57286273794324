import React from 'react'
import { Alert, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { useEffect } from 'react';
import { getInterestById, updateInterest } from '../../redux/actions/interestActions';
import { INTEREST_GET_CLEAR, INTEREST_UPDATE_CLEAR } from '../../redux/constants/interestConstants';

const initialState = { title: '' }

const EditInterest = ({handleCloseModal, id}) => {

    const dispatch = useDispatch();

    const updatedInterest = useSelector((state) => state.updatedInterest)
    const interest = useSelector((state) => state.interest)

    const [formData, setFormData] = useState(initialState);
    const [FormError, setFormError] = useState({
        title: false,
    });

    let Validate = () => {
        let value = true;

        let err = { title: false };
        setFormError({ ...err });

        if (formData.title === '') {
            value = false;
            err.title = 'Please enter Interest Name!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(updateInterest({...formData, interestId: id}, handleCloseModal))
        
            clear();
        }

    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {
        if (!updatedInterest.loading) {
            clear();
        }
    }, [updatedInterest]);

    useEffect(() => {
        dispatch(getInterestById(id))
    }, []);

    useEffect(() => {

        if (updatedInterest.error) {
            dispatch({ type: INTEREST_UPDATE_CLEAR })
        }
        if (interest.error) {
            dispatch({ type: INTEREST_GET_CLEAR })
        }
        
    }, []);

    useEffect( async () => {

        clear();

        if (!interest.loading && interest.data && interest.data.interest) {
            setFormData({
                title: interest.data.interest.title,
            })
        }

    }, [interest]);

  return (

    <div>

        <p className='modal-title'> Edit Interest </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                updatedInterest.loading || interest.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                updatedInterest.error || interest.error ?
                    <Alert severity="error"> {updatedInterest.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.title && 
                            <Alert severity="error"> {FormError.title}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Interest Name *
                        </p>
                        <input className='input' type="text" value={formData.title} onChange={(e) => setFormData({...formData, title: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Save Interest </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default EditInterest