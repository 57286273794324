import * as api from '../../api/index.js';
import { MUSIC_CATEGORIES_GET_FAIL, MUSIC_CATEGORIES_GET_REQUEST, MUSIC_CATEGORIES_GET_SUCCESS, MUSIC_CATEGORY_ADD_FAIL, MUSIC_CATEGORY_ADD_REQUEST, MUSIC_CATEGORY_ADD_SUCCESS, MUSIC_CATEGORY_DELETE_FAIL, MUSIC_CATEGORY_DELETE_REQUEST, MUSIC_CATEGORY_DELETE_SUCCESS, MUSIC_CATEGORY_GET_FAIL, MUSIC_CATEGORY_GET_REQUEST, MUSIC_CATEGORY_GET_SUCCESS, MUSIC_CATEGORY_UPDATE_FAIL, MUSIC_CATEGORY_UPDATE_REQUEST, MUSIC_CATEGORY_UPDATE_SUCCESS } from '../constants/musicCategoryConstants.js';

export const getAllMusicCategories = (search, page, pageSize) => async (dispatch) => {

    dispatch({ type: MUSIC_CATEGORIES_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllMusicCategories(token, search, page, pageSize);
      
        dispatch({ type: MUSIC_CATEGORIES_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_CATEGORIES_GET_FAIL, payload: message });
    }

};

export const addMusicCategory = (formData,handleCloseModal) => async (dispatch) => {

    dispatch({ type: MUSIC_CATEGORY_ADD_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.addMusicCategory(token, formData);
      
        dispatch({ type: MUSIC_CATEGORY_ADD_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_CATEGORY_ADD_FAIL, payload: message });
    }

};

export const updateMusicCategory = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: MUSIC_CATEGORY_UPDATE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;


        const { data } = await api.updateMusicCategory(token, formData);
      
        dispatch({ type: MUSIC_CATEGORY_UPDATE_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_CATEGORY_UPDATE_FAIL, payload: message });
    }

};

export const deleteMusicCategory = (id,handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: MUSIC_CATEGORY_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteMusicCategory(token, id);
      
        dispatch({ type: MUSIC_CATEGORY_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_CATEGORY_DELETE_FAIL, payload: message });
    }

};

export const getMusicCategoryById = (id) => async (dispatch) => {

    dispatch({ type: MUSIC_CATEGORY_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getMusicCategoryById(token, id);
      
        dispatch({ type: MUSIC_CATEGORY_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MUSIC_CATEGORY_GET_FAIL, payload: message });
    }

};