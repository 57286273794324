import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { loginReducer } from './redux/reducers/authReducers';
import { addGameCategoryReducer, addGameLevelReducer, deleteGameCategoryReducer, deleteGameLevelReducer, getAllGameCategoriesReducer, getAllGameLevelsReducer, getGameCategoryByIdReducer, updateGameCategoryReducer } from './redux/reducers/gameReducers';
import { addInterestReducer, deleteInterestReducer, getAllInterestsReducer, getInterestByIdReducer, updateInterestReducer } from './redux/reducers/interestReducers';
import { addMovieReducer, deleteMovieReducer, getAllMoviesReducer, getMovieByIdReducer, updateMovieReducer } from './redux/reducers/movieReducers';
import { addMusicCategoryReducer, deleteMusicCategoryReducer, getAllMusicCategoriesReducer, getMusicCategoryByIdReducer, updateMusicCategoryReducer } from './redux/reducers/musicCategoryReducers';
import { addMusicReducer, deleteMusicReducer, getAllMusicsReducer, getMusicByIdReducer, updateMusicReducer } from './redux/reducers/musicReducers';
import { addQuestionReducer, deleteQuestionReducer, getAllQuestionsReducer, getQuestionByIdReducer, updateQuestionReducer } from './redux/reducers/questionreducers';
import { addSubscriptionReducer, deleteSubscriptionReducer, getAllSubscriptionsReducer, getSubscriptionByIdReducer, updateSubscriptionReducer } from './redux/reducers/subscriptionReducers';
import { deleteUserReducer, getAllUsersReducer } from './redux/reducers/userReducers';
import { getAllVerificationsReducer, getVerificationByIdReducer, updateVerificationReducer } from './redux/reducers/verificationReducers';

const reducer = combineReducers({
    auth: loginReducer,
    users: getAllUsersReducer,
    deletedUser: deleteUserReducer,
    interests: getAllInterestsReducer,
    addedInterest: addInterestReducer,
    deletedInterest: deleteInterestReducer,
    interest: getInterestByIdReducer,
    updatedInterest: updateInterestReducer,
    addedMusicCategory: addMusicCategoryReducer,
    deletedMusicCategory: deleteMusicCategoryReducer,
    musicCategories: getAllMusicCategoriesReducer,
    musicCategory: getMusicCategoryByIdReducer,
    updatedMusicCategory: updateMusicCategoryReducer,
    addedMovie: addMovieReducer,
    deletedMovie: deleteMovieReducer,
    movies: getAllMoviesReducer,
    movie: getMovieByIdReducer,
    updatedMovie: updateMovieReducer,
    addedMusic: addMusicReducer,
    deletedMusic: deleteMusicReducer,
    musics: getAllMusicsReducer,
    music: getMusicByIdReducer,
    updatedMusic: updateMusicReducer,
    addedSubscription: addSubscriptionReducer,
    deletedSubscription: deleteSubscriptionReducer,
    subscriptions: getAllSubscriptionsReducer,
    subscription: getSubscriptionByIdReducer,
    updatedSubscription: updateSubscriptionReducer,
    addedGameCategory: addGameCategoryReducer,
    deletedGameCategory: deleteGameCategoryReducer,
    gameCategories: getAllGameCategoriesReducer,
    gameCategory: getGameCategoryByIdReducer,
    updatedGameCategory: updateGameCategoryReducer,
    addedGameLevel: addGameLevelReducer,
    deletedGameLevel: deleteGameLevelReducer,
    gameLevels: getAllGameLevelsReducer,
    addedQuestion: addQuestionReducer,
    deletedQuestion: deleteQuestionReducer,
    questions: getAllQuestionsReducer,
    question: getQuestionByIdReducer,
    updatedQuestion: updateQuestionReducer,
    updatedVerification: updateVerificationReducer,
    verifications: getAllVerificationsReducer,
    verification: getVerificationByIdReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
