import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import AddSubscription from './AddSubscription';
import EditSubscription from './EditSubscription';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    minHeight: '50px',
    maxHeight: '90vh',
    bgcolor: '#FFFFFF',
    padding: '20px',
    borderRadius: '10px',
    overflowY: 'scroll'
};

const SubscriptionModal = ({ openModal, handleCloseModal, modalType, id }) => {

  return (

    <Modal
        open={openModal}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
    >
      <Box sx={style}>

        <AiOutlineCloseCircle className='close-button' onClick={() => handleCloseModal()}/>

        {
          modalType === 'add' ?

          <AddSubscription handleCloseModal={handleCloseModal}/>

          :

          modalType === 'edit' ?

          <EditSubscription handleCloseModal={handleCloseModal} id={id}/>

          :

          <></>

        }

      </Box>
    </Modal>

  )
}

export default SubscriptionModal