export const MUSIC_CATEGORIES_GET_REQUEST = 'MUSIC_CATEGORIES_GET_REQUEST'
export const MUSIC_CATEGORIES_GET_SUCCESS = 'MUSIC_CATEGORIES_GET_SUCCESS'
export const MUSIC_CATEGORIES_GET_FAIL = 'MUSIC_CATEGORIES_GET_FAIL'

export const MUSIC_CATEGORY_ADD_REQUEST = 'MUSIC_CATEGORY_ADD_REQUEST'
export const MUSIC_CATEGORY_ADD_SUCCESS = 'MUSIC_CATEGORY_ADD_SUCCESS'
export const MUSIC_CATEGORY_ADD_FAIL = 'MUSIC_CATEGORY_ADD_FAIL'
export const MUSIC_CATEGORY_ADD_CLEAR = 'MUSIC_CATEGORY_ADD_CLEAR'

export const MUSIC_CATEGORY_DELETE_REQUEST = 'MUSIC_CATEGORY_DELETE_REQUEST'
export const MUSIC_CATEGORY_DELETE_SUCCESS = 'MUSIC_CATEGORY_DELETE_SUCCESS'
export const MUSIC_CATEGORY_DELETE_FAIL = 'MUSIC_CATEGORY_DELETE_FAIL'
export const MUSIC_CATEGORY_DELETE_CLEAR = 'MUSIC_CATEGORY_DELETE_CLEAR'

export const MUSIC_CATEGORY_UPDATE_REQUEST = 'MUSIC_CATEGORY_UPDATE_REQUEST'
export const MUSIC_CATEGORY_UPDATE_SUCCESS = 'MUSIC_CATEGORY_UPDATE_SUCCESS'
export const MUSIC_CATEGORY_UPDATE_FAIL = 'MUSIC_CATEGORY_UPDATE_FAIL'
export const MUSIC_CATEGORY_UPDATE_CLEAR = 'MUSIC_CATEGORY_UPDATE_CLEAR'

export const MUSIC_CATEGORY_GET_REQUEST = 'MUSIC_CATEGORY_GET_REQUEST'
export const MUSIC_CATEGORY_GET_SUCCESS = 'MUSIC_CATEGORY_GET_SUCCESS'
export const MUSIC_CATEGORY_GET_FAIL = 'MUSIC_CATEGORY_GET_FAIL'
export const MUSIC_CATEGORY_GET_CLEAR = 'MUSIC_CATEGORY_GET_CLEAR'