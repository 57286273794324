import { MOVIES_GET_FAIL, MOVIES_GET_REQUEST, MOVIES_GET_SUCCESS, MOVIE_ADD_CLEAR, MOVIE_ADD_FAIL, MOVIE_ADD_REQUEST, MOVIE_ADD_SUCCESS, MOVIE_DELETE_CLEAR, MOVIE_DELETE_FAIL, MOVIE_DELETE_REQUEST, MOVIE_DELETE_SUCCESS, MOVIE_GET_CLEAR, MOVIE_GET_FAIL, MOVIE_GET_REQUEST, MOVIE_GET_SUCCESS, MOVIE_UPDATE_CLEAR, MOVIE_UPDATE_FAIL, MOVIE_UPDATE_REQUEST, MOVIE_UPDATE_SUCCESS } from "../constants/movieConstants";

export const getAllMoviesReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MOVIES_GET_REQUEST:
            return { loading: true };

        case MOVIES_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case MOVIES_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const addMovieReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MOVIE_ADD_REQUEST:
            return { loading: true };

        case MOVIE_ADD_SUCCESS:
            return { loading: false, data: action.payload };

        case MOVIE_ADD_FAIL:
            return { loading: false, error: action.payload };

        case MOVIE_ADD_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const deleteMovieReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MOVIE_DELETE_REQUEST:
            return { loading: true };

        case MOVIE_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case MOVIE_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case MOVIE_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const updateMovieReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MOVIE_UPDATE_REQUEST:
            return { loading: true };

        case MOVIE_UPDATE_SUCCESS:
            return { loading: false, data: action.payload };

        case MOVIE_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case MOVIE_UPDATE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};

export const getMovieByIdReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case MOVIE_GET_REQUEST:
            return { loading: true };

        case MOVIE_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case MOVIE_GET_FAIL:
            return { loading: false, error: action.payload };

        case MOVIE_GET_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};