import { USERS_GET_FAIL, USERS_GET_REQUEST, USERS_GET_SUCCESS, USER_DELETE_CLEAR, USER_DELETE_FAIL, USER_DELETE_REQUEST, USER_DELETE_SUCCESS } from "../constants/userConstants";

export const getAllUsersReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case USERS_GET_REQUEST:
            return { loading: true };

        case USERS_GET_SUCCESS:
            return { loading: false, data: action.payload };

        case USERS_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const deleteUserReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true };

        case USER_DELETE_SUCCESS:
            return { loading: false, data: action.payload };

        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case USER_DELETE_CLEAR:
            return { loading: false };

        default:
            return state;
    }
};