import * as api from '../../api/index.js';
import { MOVIES_GET_FAIL, MOVIES_GET_REQUEST, MOVIES_GET_SUCCESS, MOVIE_ADD_FAIL, MOVIE_ADD_REQUEST, MOVIE_ADD_SUCCESS, MOVIE_DELETE_FAIL, MOVIE_DELETE_REQUEST, MOVIE_DELETE_SUCCESS, MOVIE_GET_FAIL, MOVIE_GET_REQUEST, MOVIE_GET_SUCCESS, MOVIE_UPDATE_FAIL, MOVIE_UPDATE_REQUEST, MOVIE_UPDATE_SUCCESS } from '../constants/movieConstants.js';

export const getAllMovies = (search, page, pageSize) => async (dispatch) => {

    dispatch({ type: MOVIES_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getAllMovies(token, search, page, pageSize);
      
        dispatch({ type: MOVIES_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MOVIES_GET_FAIL, payload: message });
    }

};

export const addMovie = (fd,handleCloseModal) => async (dispatch) => {

    dispatch({ type: MOVIE_ADD_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const formData = new FormData();

        formData.append("movieName", fd.movieName)
        formData.append("image", fd.image)

        const { data } = await api.addMovie(token, formData);
      
        dispatch({ type: MOVIE_ADD_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MOVIE_ADD_FAIL, payload: message });
    }

};

export const updateMovie = (formData, handleCloseModal) => async (dispatch) => {

    dispatch({ type: MOVIE_UPDATE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.updateMovie(token, formData);
      
        dispatch({ type: MOVIE_UPDATE_SUCCESS, payload: data });

        handleCloseModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MOVIE_UPDATE_FAIL, payload: message });
    }

};

export const deleteMovie = (id, handleCloseDeleteModal) => async (dispatch) => {

    dispatch({ type: MOVIE_DELETE_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.deleteMovie(token, id);
      
        dispatch({ type: MOVIE_DELETE_SUCCESS, payload: data });

        handleCloseDeleteModal()

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MOVIE_DELETE_FAIL, payload: message });
    }

};

export const getMovieById = (id) => async (dispatch) => {

    dispatch({ type: MOVIE_GET_REQUEST });

    try {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = user.token;

        const { data } = await api.getMovieById(token, id);
      
        dispatch({ type: MOVIE_GET_SUCCESS, payload: data });

    } catch (error) {

        const message =
            error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: MOVIE_GET_FAIL, payload: message });
    }

};