import React, { useEffect } from 'react'
import { Alert, Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { addMusic } from '../../redux/actions/musicActions';
import { MUSIC_ADD_CLEAR } from '../../redux/constants/musicConstants';
import ReactAudioPlayer from 'react-audio-player';
import { getAllMusicCategories } from '../../redux/actions/musicCategoryActions';
import { getAllMovies } from '../../redux/actions/movieActions';

const initialState = { musicName: '', audio: null, categoryId: '', movieId: '' }

const AddMusic = ({handleCloseModal}) => {

    const dispatch = useDispatch();

    const addedMusic = useSelector((state) => state.addedMusic)
    const musicCategories = useSelector((state) => state.musicCategories)
    const movies = useSelector((state) => state.movies)

    const [formData, setFormData] = useState(initialState);
    const [FormError, setFormError] = useState({
        musicName: false,
        audio: false,
        categoryId: false,
        movieId: false,
    });

    let Validate = () => {
        let value = true;

        let err = {  
            musicName: false,
            audio: false,
            categoryId: false,
            movieId: false 
        };
        setFormError({ ...err });

        if (formData.musicName === '') {
            value = false;
            err.musicName = 'Please enter Music Name!';
        }

        if (formData.audio === null) {
            value = false;
            err.audio = 'Please select Audio file!';
        }

        if (formData.categoryId === '') {
            value = false;
            err.categoryId = 'Please select category!';
        }

        if (formData.movieId === '') {
            value = false;
            err.movieId = 'Please select movie!';
        }

        setFormError({ ...err });
        return value;
    };

    const submitHandler = (e) => {

        if (Validate()) {
            e.preventDefault();
            dispatch(addMusic(formData,handleCloseModal))
        
            clear();
        }
    }

    const clear = () => {
        setFormData(initialState);
    };

    useEffect(() => {

        if (addedMusic.error) {
            dispatch({ type: MUSIC_ADD_CLEAR })
        }
        dispatch(getAllMusicCategories('', 1, 1000))
        dispatch(getAllMovies('', 1, 1000))
        
    }, []);

  return (

    <div>

        <p className='modal-title'> Add Music </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { my: 1 },
            }}
            autoComplete="off"
            onSubmit={(e) => submitHandler(e)}
        >

            {
                addedMusic.loading ?

                <div className='loader-div'>
                    <TailSpin
                        color='#C847F4'
                        height={100}
                        width={100}
                    />
                </div>
                :

                addedMusic.error ?
                    <Alert severity="error"> {addedMusic.error}</Alert>
                :

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        {
                            FormError.musicName && 
                            <Alert severity="error"> {FormError.musicName}</Alert>
                        }
                        {
                            FormError.audio && 
                            <Alert severity="error"> {FormError.audio}</Alert>
                        }
                        {
                            FormError.categoryId && 
                            <Alert severity="error"> {FormError.categoryId}</Alert>
                        }
                        {
                            FormError.movieId && 
                            <Alert severity="error"> {FormError.movieId}</Alert>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Music Name *
                        </p>
                        <input className='input' type="text" value={formData.musicName} onChange={(e) => setFormData({...formData, musicName: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Audio *
                        </p>
                        {
                            formData.audio &&
                            <>
                                <ReactAudioPlayer
                                    src={URL.createObjectURL(formData.audio)}
                                    controls
                                />
                                <br/>
                                <br/>
                            </>
                        }
                        <input type="file" multiple accept=".mp3,audio/*" onChange={(e) => setFormData({...formData, audio: e.target.files[0]})} />
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Music Category *
                        </p>
                        <select className='input' value={formData.categoryId} onChange={(e) => setFormData({...formData, categoryId: e.target.value})}>
                            <option value="">Select Music Category</option>
                            {
                                musicCategories.data && musicCategories.data.musicCategories.map((category, index) => (
                                    <option value={category._id} key={index}>{category.categoryName}</option>
                                ))
                            }
                        </select>
                    </Grid>

                    <Grid item xs={12}>
                        <p className='input-label'>
                            Movie *
                        </p>
                        <select className='input' value={formData.movieId} onChange={(e) => setFormData({...formData, movieId: e.target.value})}>
                            <option value="">Select Movie</option>
                            {
                                movies.data && movies.data.movies.map((movie, index) => (
                                    <option value={movie._id} key={index}>{movie.movieName}</option>
                                ))
                            }
                        </select>
                    </Grid>

                    <Grid item xs={12} className='form-button-container'>
                        <button className='form-action-button' onClick={() => handleCloseModal()}> Cancel </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className='form-action-button-submit' type='submit'> Add Music </button>
                    </Grid>

                </Grid>

            }
        
        </Box>

    </div>

  )

}

export default AddMusic