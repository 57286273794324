export const INTERESTS_GET_REQUEST = 'INTERESTS_GET_REQUEST'
export const INTERESTS_GET_SUCCESS = 'INTERESTS_GET_SUCCESS'
export const INTERESTS_GET_FAIL = 'INTERESTS_GET_FAIL'

export const INTEREST_ADD_REQUEST = 'INTEREST_ADD_REQUEST'
export const INTEREST_ADD_SUCCESS = 'INTEREST_ADD_SUCCESS'
export const INTEREST_ADD_FAIL = 'INTEREST_ADD_FAIL'
export const INTEREST_ADD_CLEAR = 'INTEREST_ADD_CLEAR'

export const INTEREST_DELETE_REQUEST = 'INTEREST_DELETE_REQUEST'
export const INTEREST_DELETE_SUCCESS = 'INTEREST_DELETE_SUCCESS'
export const INTEREST_DELETE_FAIL = 'INTEREST_DELETE_FAIL'
export const INTEREST_DELETE_CLEAR = 'INTEREST_DELETE_CLEAR'

export const INTEREST_UPDATE_REQUEST = 'INTEREST_UPDATE_REQUEST'
export const INTEREST_UPDATE_SUCCESS = 'INTEREST_UPDATE_SUCCESS'
export const INTEREST_UPDATE_FAIL = 'INTEREST_UPDATE_FAIL'
export const INTEREST_UPDATE_CLEAR = 'INTEREST_UPDATE_CLEAR'

export const INTEREST_GET_REQUEST = 'INTEREST_GET_REQUEST'
export const INTEREST_GET_SUCCESS = 'INTEREST_GET_SUCCESS'
export const INTEREST_GET_FAIL = 'INTEREST_GET_FAIL'
export const INTEREST_GET_CLEAR = 'INTEREST_GET_CLEAR'