export const QUESTIONS_GET_REQUEST = 'QUESTIONS_GET_REQUEST'
export const QUESTIONS_GET_SUCCESS = 'QUESTIONS_GET_SUCCESS'
export const QUESTIONS_GET_FAIL = 'QUESTIONS_GET_FAIL'

export const QUESTION_ADD_REQUEST = 'QUESTION_ADD_REQUEST'
export const QUESTION_ADD_SUCCESS = 'QUESTION_ADD_SUCCESS'
export const QUESTION_ADD_FAIL = 'QUESTION_ADD_FAIL'
export const QUESTION_ADD_CLEAR = 'QUESTION_ADD_CLEAR'

export const QUESTION_DELETE_REQUEST = 'QUESTION_DELETE_REQUEST'
export const QUESTION_DELETE_SUCCESS = 'QUESTION_DELETE_SUCCESS'
export const QUESTION_DELETE_FAIL = 'QUESTION_DELETE_FAIL'
export const QUESTION_DELETE_CLEAR = 'QUESTION_DELETE_CLEAR'

export const QUESTION_UPDATE_REQUEST = 'QUESTION_UPDATE_REQUEST'
export const QUESTION_UPDATE_SUCCESS = 'QUESTION_UPDATE_SUCCESS'
export const QUESTION_UPDATE_FAIL = 'QUESTION_UPDATE_FAIL'
export const QUESTION_UPDATE_CLEAR = 'QUESTION_UPDATE_CLEAR'

export const QUESTION_GET_REQUEST = 'QUESTION_GET_REQUEST'
export const QUESTION_GET_SUCCESS = 'QUESTION_GET_SUCCESS'
export const QUESTION_GET_FAIL = 'QUESTION_GET_FAIL'
export const QUESTION_GET_CLEAR = 'QUESTION_GET_CLEAR'